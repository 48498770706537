import React, { useState, useEffect, useCallback } from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useHistory, useLocation } from "react-router-dom";

import "./Event Data Modal Components/style.css";

import { useDropzone } from "react-dropzone";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";

import { debounce } from "lodash";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import JobTab from "./Event Data Modal Components/JobTab";
import ClientTab from "./Event Data Modal Components/ClientTab";
import ShootTab from "./Event Data Modal Components/ShootTab";

import { useAtom } from "jotai";
import {
  themeAtom,
  isIdleAtom,
  isElectronAtom,
  // isMobileBrowserAtom,
  listsAtom,
  isAdminAtom,
  currentStaffDataAtom,
  isDebouncingAtom,
  eventDataModalTabValueAtom,
} from "../-Atoms";

import { publish } from "../GlobalFunctions/customEvent";
import { v4 as uuidv4 } from "uuid";

const showDock = () => {
  publish("showDock");
};
// const hideDock = () => {
//   publish("hideDock");
// };

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const studios = [
  "Studio 1",
  "Studio 2A",
  "Studio 2B",
  "Studio 2C",
  "Studio 3",
  "Studio 4",
  "Staff",
  "N/A",
  "Critical Events",
  "Post Production",
  "Adam",
  "Location",
];
const jobTypes = [
  "Misc (-M)",
  "Creative Life (-LC)",
  "Creative Flatlay (-PC)",
  "Design (-D)",
  "Life (-L)",
  "Location Shoot (-L)",
  "Ghost (-P)",
  "Ghost/Flatlay (-P)",
  "Ghost/Product (-P)",
  "Ghost/Jewellery (-P)",
  "Flatlay (-P)",
  "Hanging Flatlay (-P)",
  "Jewellery (-P)",
  "Product (-P)",
  "Retouching (-R)",
  "Video (-V)",
  "Video Editing (-E)",
];
const dayTypes = ["Full Day", "Half Day"];

const EventDataModal = ({
  firebase,
  event,
  events,
  findStaff,
  staffData,
  eventDataModalOpen,
  stopModalClose,
  setStopModalClose,
  setEventDataModal,
  setEventDataModalOpen,
  height,
  clientData,
  calendarColours,
  confirmationStatusEmailOpen,
  setConfirmationStatusEmailOpen,
  firstConfirmationStatusEmailOpen,
  setFirstConfirmationStatusEmailOpen,
  setConfirmationEmailData,
  isEventTomorrow,
  // theme,
  newCardID,
  modalWidth,
}) => {
  const [theme] = useAtom(themeAtom);
  const [eventDataModalTabValue] = useAtom(eventDataModalTabValueAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);

  const [isIdle] = useAtom(isIdleAtom);
  const [isElectron] = useAtom(isElectronAtom);
  // const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [lists, setLists] = useAtom(listsAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  // const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [isDebouncing] = useAtom(isDebouncingAtom);

  const [isElectronCheck, setIsElectronCheck] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [jobData, setJobData] = useState({});

  const [value, setValue] = useState(eventDataModalTabValue);

  useEffect(() => {
    if (jobData?.studio) {
      if (
        !jobData.studio.includes("Studio") &&
        !jobData.studio.includes("Location")
      ) {
        setValue(0);
      } else {
        setValue(eventDataModalTabValue);
      }
    }
  }, [jobData?.studio, eventDataModalTabValue]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const main = () => {
      if (isElectronCheck) return;
      if (!jobData.id) return;
      setIsElectronCheck(true);
      if (event.extendedProps.recurringEvent) {
        setValue(0);
        return;
      }
    };
    main();
    //   // eslint-disable-next-line
  }, [jobData, isElectron, isElectronCheck, event]);

  const [jobClientData, setJobClientData] = useState({});
  const [specialEvent, setSpecialEvent] = useState(false);
  const [jobNumber, setJobNumber] = useState("");

  const [attachments, setAttachments] = useState([]);
  const [clientAttachments, setClientAttachments] = useState([]);

  const [tracking, setTracking] = useState({});
  const [jobName, setJobName] = useState("");

  const [canEdit, setCanEdit] = useState(false);
  const [canEditBasic, setCanEditBasic] = useState(false);

  useEffect(() => {
    if (!jobData) return;
    if (!jobData.client) return;
    if (!clientData) return;
    if (clientData.brand) {
      setJobClientData(clientData);
      return;
    }
    clientData.forEach((c) => {
      if (c.brand === jobData.client) setJobClientData(c);
    });
  }, [jobData, clientData, setJobClientData]);

  useEffect(() => {
    if (!currentStaffData) {
      setCanEdit(false);
      return;
    }
    setCanEditBasic(true);
    if (specialEvent) {
      setCanEdit(true);
      return;
    }
    if (currentStaffData.isSuperAdmin) {
      setCanEdit(true);
      return;
    }
    if (currentStaffData.isAdmin) {
      setCanEdit(true);
      return;
    }
    setCanEdit(false);
  }, [currentStaffData, specialEvent]);

  useEffect(() => {
    if (jobClientData?.attachments?.length >= 0) {
      setClientAttachments([...jobClientData?.attachments]);
    }
  }, [jobClientData]);

  const [filesUploading, setFilesUploading] = useState(false);

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("sm"));

  useEffect(() => {
    let data = event?.extendedProps;
    if (jobData.id) data = jobData;

    setTracking(data?.tracking || {});

    if (data?.jobNumber !== jobNumber) {
      setJobNumber(data?.jobNumber);
    }
    if (data?.jobName !== jobName) {
      setJobName(data?.jobName);
    }

    if (data?.attachments?.length >= 0) {
      setAttachments([...data?.attachments]);
    }
    // eslint-disable-next-line
  }, [event, jobData]);

  useEffect(() => {
    if (event.id) {
      let unsub = firebase
        .firestore()
        .collection("JobData")
        .doc(event.id)
        .onSnapshot((doc) => {
          try {
            const data = doc.data();
            data.id = doc.id;
            // event.start = data.start;
            // event.end = data.end;
            // event.extendedProps.id = doc.id;
            setJobData({ ...data });
          } catch (e) {
            console.error(e);
          }
        });
      // if (isIdle) {
      //   unsub();
      // }
      return () => {
        unsub();
      };
      // eslint-disable-next-line
    }
  }, [firebase, event, isIdle]);

  const onDrop = async (e) => {
    if (!canEdit) return;
    setStopModalClose(true);
    setFilesUploading(true);
    let newAttachments = [];
    for (let i = 0; i < e.length; i++) {
      const file = e[i];
      if (file.size > 150000000) {
        alert("file must be under 150MB");
      } else {
        let url;
        const res = await dbx
          .filesUpload({
            path: `/-Job Attachments/${jobData.id}/${file.name}`,
            autorename: false,
            mode: "add",
            mute: false,
            contents: file,
          })
          .catch((e) => {
            setStopModalClose(false);
            setFilesUploading(false);
            console.error(e);
          });

        await dbx
          .sharingListSharedLinks({
            path: res.result.path_display,
            direct_only: true,
          })
          .then((data) => {
            url = data.result.links[0].url;
          })
          .catch(async (e) => {
            console.error(e);
            await dbx
              .sharingCreateSharedLinkWithSettings({
                path: res.result.path_display,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer",
                },
              })
              .then((data) => {
                url = data.result.url;
              })
              .catch((e) => {
                console.error(e);
                setStopModalClose(false);
                setFilesUploading(false);
              });
          });
        if (url) newAttachments.push({ fileName: file.name, url: url });
      }
    }
    if (newAttachments) {
      newAttachments.forEach((a) => {
        attachments.push(a);
      });
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ attachments: attachments || "" }, { merge: true })
        .catch((error) => {
          console.error(error);
        });
      setAttachments([...attachments, ...newAttachments]);
    }
    setStopModalClose(false);
    setFilesUploading(false);
  };

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (isDragActive) {
      document.getElementById("modal-div").scrollTop = 0;
    }
  }, [isDragActive]);
  const modalContainerStyle = {
    overflowY: isDragActive ? "hidden" : "auto",
    position: "absolute",
    background: isDragActive ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  const modalContainerStyle2 = {
    overflowY: "auto",
    position: value > 0 ? "absolute" : "",
    background: isDragActive ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  const modalContainerInnerStyle = {
    display: isDragActive ? "flex" : "none",
    position: "absolute",
    border: isDragActive ? "5px dashed rgba(25,25,25,0.5)" : "",
    top: 5,
    bottom: 5,
    left: 5,
    right: 5,
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2em",
    fontWeight: 600,
  };
  const modalContainerInnerUploadStyle = {
    overflowY: filesUploading ? "hidden" : "auto",
    display: filesUploading ? "flex" : "none",
    position: "absolute",
    background: filesUploading ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0)",
    border: filesUploading ? "5px dashed rgba(25,25,25,0.5)" : "",
    top: 5,
    bottom: 5,
    left: 5,
    right: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "2em",
    fontWeight: 600,
  };

  const [deleteJobButtonShow, setDeleteJobButtonShow] = useState(true);
  const [confirmDeleteJobButtonShow, setConfirmDeleteJobButtonShow] =
    useState(false);
  const [confirmDeleteJobButtonText, setConfirmDeleteJobButtonText] = useState(
    "CONFIRM DELETE JOB?"
  );

  const deleteJob = () => {
    if (!canEdit) return;
    setDeleteJobButtonShow(false);
    setConfirmDeleteJobButtonShow(true);
  };

  const confirmDeleteJob = async () => {
    if (!canEdit) return;
    setConfirmDeleteJobButtonText(
      <CircularProgress size={28} style={{ color: "#ffffff" }} />
    );

    lists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        list.items.splice(list.items.indexOf(jobData.id), 1);
      }
    });
    setLists(lists);
    await firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });

    await firebase.firestore().collection("JobData").doc(jobData.id).delete();
    // await new Promise((r) => setTimeout(r, 200));
    setEventDataModalOpen(false);
    showDock();
    setEventDataModal({});
    history.push(location.pathname);

    if (isElectron) {
      // window.ipcRenderer?.send("setCalendarWindowBounds");
      window.ipcRenderer?.send("eval", "setCalendarWindowBounds()");
    }
  };

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };
  // eslint-disable-next-line
  const updateFSNow = useCallback(
    debounce((firebase, id, field, value) => {
      firebase
        .firestore()
        .collection("JobData")
        .doc(id)
        .update({ [field]: value }, { merge: true })
        .catch((error) => {
          console.error(error);
        });
    }, 1200),
    []
  );

  const duplicateJob = async () => {
    if (!canEdit) return;
    handleMenuClose();
    const duplicatedJob = { ...jobData };
    duplicatedJob.multipleDays = "";
    duplicatedJob.confirmationStatus = "tbc";
    duplicatedJob.dueDate = null;
    duplicatedJob.express = false;
    duplicatedJob.expressDueDate = null;
    duplicatedJob.id = "";
    duplicatedJob.catering = [];
    duplicatedJob.imagesScheduledToBeShot = "";
    duplicatedJob.imagesProcessed = "";
    duplicatedJob.imagesProcessedNumbers = 0;
    duplicatedJob.retoucher = "";
    duplicatedJob.retouchingTime = "";
    duplicatedJob.models = [];
    duplicatedJob.hmu = [];
    duplicatedJob.eventNotes = "";
    duplicatedJob.shootSpecialRequirements = "";
    duplicatedJob.invoicingSpecialRequirements = "";
    duplicatedJob.retouchingSpecialRequirements = "";
    duplicatedJob.checklists = [
      {
        name: "Colour Corrections",
        id: uuidv4(),
        checklistItems: [],
      },
      {
        name: "Colour Changes",
        id: uuidv4(),
        checklistItems: [],
      },
      {
        name: "Retouching Notes",
        id: uuidv4(),
        checklistItems: [],
      },
    ];
    await firebase
      .firestore()
      .collection("JobData")
      .add(duplicatedJob)
      .then((doc) => {
        return doc.id;
      });
    setEventDataModalOpen(false);
    showDock();
    history.push(location.pathname);
  };

  const [showLinkBlock, setShowLinkBlock] = useState(false);

  const [supplyLink, setSupplyLink] = useState("");
  useEffect(() => {
    function main() {
      if (!jobClientData.suppliedLinks) return;
      jobClientData.suppliedLinks.forEach((s) => {
        if (s.jobNumber === jobData.jobNumber) {
          setSupplyLink(s);
        }
      });
    }
    main();
  }, [clientData, jobData, jobClientData]);

  const [copyCardIdToClipboardOpen, setCopyCardIdToClipboardOpen] =
    useState(false);

  const handleCopyCardIdToClipboardClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopyCardIdToClipboardOpen(false);
  };

  // const tabsHeaderWidthMinus = 50;

  const [panelLocked, setPanelLocked] = useState(false);
  const [lockedPanelExists, setLockedPanelExists] = useState(false);
  useEffect(() => {
    if (isElectron) {
      const l = window.ipcRenderer?.sendSync("checkLockedWindow");
      setLockedPanelExists(l);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!panelLocked ? (
        <div
          style={{
            position: "absolute",
            top: 6,
            right: 5,
            zIndex: 99999,
          }}
        >
          {!isDebouncing ? (
            <IconButton
              onClick={() => {
                if (isDebouncing) return;
                if (!stopModalClose) setEventDataModalOpen(false);
                showDock();
                history.push(location.pathname);

                if (isElectron) {
                  window.ipcRenderer?.send("eval", "setCalendarWindowBounds()");
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      ) : null}
      {isElectron && !lockedPanelExists ? (
        <div
          style={{
            position: "absolute",
            top: panelLocked ? 6 : 50,
            right: 5,
            zIndex: 99999,
          }}
        >
          <IconButton
            onClick={() => {
              if (isElectron) {
                if (!panelLocked)
                  window.ipcRenderer?.send("lockCalendarWindow");
                if (panelLocked)
                  window.ipcRenderer?.send("unlockCalendarWindow");
              }

              setPanelLocked((prev) => !prev);
            }}
          >
            {panelLocked ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: theme === "dark" ? "#1f1f1f" : "#ffffff",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        className="stickytabs"
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Job" disabled={isDebouncing} />
          <Tab label="Client" disabled={isDebouncing} />
          <Tab label="Shoot" disabled={isDebouncing} />
        </Tabs>
      </div>

      <div style={{ overflow: "auto" }}>
        <div
          id="modal-div"
          style={{
            ...modalContainerStyle,
            background: theme === "dark" ? "#1f1f1f" : "#ffffff",
          }}
          {...getRootProps()}
          onClick={() => {}}
        >
          <div style={modalContainerInnerStyle}>
            <span>Drop file to add to attachments</span>
          </div>
          <div style={modalContainerInnerUploadStyle}>
            <div>Uploading Files...</div>
            <div>Do not close this window.</div>
          </div>
          <Menu
            anchorEl={menuAnchorElement}
            keepMounted
            open={Boolean(menuAnchorElement)}
            onClose={handleMenuClose}
            disableScrollLock={true}
            style={{ marginTop: 10 }}
          >
            <MenuItem onClick={duplicateJob}>Duplicate</MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(jobData.id);
                setCopyCardIdToClipboardOpen(true);
                handleMenuClose();
              }}
            >
              {jobData.id}
            </MenuItem>
          </Menu>
          <div
            style={{
              margin: isMobile ? "20px" : "15px",
              overflowX: "hidden",
            }}
            id="eventDataModal"
          >
            <TabPanel
              value={value}
              index={0}
              style={{
                marginTop: 120,
                marginLeft: 15,
                marginRight: 15,
                overflowX: "hidden",
              }}
            >
              <JobTab
                firebase={firebase}
                jobData={jobData}
                clientData={clientData}
                studios={studios}
                jobTypes={jobTypes}
                dayTypes={dayTypes}
                handleMenuClose={handleMenuClose}
                handleMenuClick={handleMenuClick}
                showLinkBlock={showLinkBlock}
                setShowLinkBlock={setShowLinkBlock}
                event={event}
                events={events}
                isAdmin={isAdmin}
                canEdit={canEdit}
                staffData={staffData}
                calendarColours={calendarColours}
                confirmationStatusEmailOpen={confirmationStatusEmailOpen}
                setConfirmationStatusEmailOpen={setConfirmationStatusEmailOpen}
                firstConfirmationStatusEmailOpen={
                  firstConfirmationStatusEmailOpen
                }
                setFirstConfirmationStatusEmailOpen={
                  setFirstConfirmationStatusEmailOpen
                }
                setConfirmationEmailData={setConfirmationEmailData}
                isEventTomorrow={isEventTomorrow}
                setJobNumber={setJobNumber}
                jobNumber={jobNumber}
                supplyLink={supplyLink}
                updateFSNow={updateFSNow}
                updateFS={updateFS}
                confirmDeleteJobButtonText={confirmDeleteJobButtonText}
                confirmDeleteJob={confirmDeleteJob}
                confirmDeleteJobButtonShow={confirmDeleteJobButtonShow}
                deleteJob={deleteJob}
                deleteJobButtonShow={deleteJobButtonShow}
                tracking={tracking}
                attachments={attachments}
                clientAttachments={clientAttachments}
                isMobile={isMobile}
                specialEvent={specialEvent}
                setSpecialEvent={setSpecialEvent}
                jobClientData={jobClientData}
                setJobClientData={setJobClientData}
                currentUser={currentStaffData}
                canEditBasic={canEditBasic}
                setStopModalClose={setStopModalClose}
                setAttachments={setAttachments}
                theme={theme}
                newCardID={newCardID}
              />
            </TabPanel>
          </div>
        </div>
      </div>
      <div
        style={{
          ...modalContainerStyle2,
          position: value === 1 ? "absolute" : "",
          margin: 15,
          // zIndex: 300,
          // margin: isMobile ? 20 : 10,
        }}
      >
        <TabPanel value={value} index={1} style={{ marginTop: 50 }}>
          <ClientTab
            jobClientData={jobClientData}
            updateFS={updateFS}
            firebase={firebase}
            canEdit={canEdit}
            theme={theme}
            modalWidth={modalWidth}
          />
        </TabPanel>
      </div>
      <div
        style={{
          ...modalContainerStyle2,
          position: value === 2 ? "absolute" : "",
          width: isElectron ? "100%" : "100%",
          // margin: 15,
          // margin: isMobile ? 20 : 15,
        }}
      >
        <TabPanel value={value} index={2} style={{ marginTop: 40 }}>
          <div>&zwnj;</div>
          <div>&zwnj;</div>
          <ShootTab
            firebase={firebase}
            // jobDataInit={jobData}
            jobData={jobData}
            setJobData={setJobData}
            clientDataInit={jobClientData}
            staffData={staffData}
            theme={theme}
            modalWidth={modalWidth}
            isMobile={isMobile}
            currentUser={currentStaffData}
            setStopModalClose={setStopModalClose}
            kanbanView={false}
            soloClose={false}
            modalJobData={{}}
            modalClientData={{}}
          />
        </TabPanel>
      </div>
      {/* ) : null} */}
      <Snackbar
        open={copyCardIdToClipboardOpen}
        autoHideDuration={5000}
        onClose={handleCopyCardIdToClipboardClose}
        message="Job ID Copied to Clipboard"
      />
    </>
  );
};

export default EventDataModal;

const updateFS = debounceCustom(async (firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(id)
    .set({ [field]: value || null }, { merge: true });
}, 800);

function debounceCustom(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
