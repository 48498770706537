import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDEditor from "@uiw/react-md-editor";

import { useAtom } from "jotai";
import { isAdminAtom, jobTypesAtom } from "../-Atoms";

const NotesContainer = ({
  sortedNotes,
  selectedNotesFilter,
  isMobile,
  modalWidth,
  theme,
  jobTab,
  newNoteModalOpen,
  setNewNoteModalOpen,
  setNewSelectedNotesFilter,
  setNewSelectedNotesJobTypeFilter,
  setNewNoteKind,
  setNewNotesText,
  setNewNotesID,
  setNewNote,
  firebase,
  jobData,
  clientData,
  clientTab,
  shootTab,
  kind,
}) => {
  const [isAdmin] = useAtom(isAdminAtom);
  const [jobTypes] = useAtom(jobTypesAtom);

  return (
    <div
      style={{
        padding: 20,
        marginTop: 20,
        // marginBottom: 10,
        width: "100%",
        display: "flex",
        gap: 20,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {sortedNotes &&
        sortedNotes.map((note, index) => {
          if (!shouldShow(kind, note, isAdmin, jobTypes, jobData)) return null;
          return (
            <div key={`note_${index}`} style={{ width: "100%" }}>
              {shouldShow(kind, note, isAdmin, jobTypes, jobData) ? (
                <NoteContainer
                  index={index}
                  jobTab={jobTab}
                  note={note}
                  sortedNotes={sortedNotes}
                  selectedNotesFilter={selectedNotesFilter}
                  isMobile={isMobile}
                  modalWidth={modalWidth}
                  theme={theme}
                  newNoteModalOpen={newNoteModalOpen}
                  setNewNoteModalOpen={setNewNoteModalOpen}
                  setNewSelectedNotesFilter={setNewSelectedNotesFilter}
                  setNewSelectedNotesJobTypeFilter={
                    setNewSelectedNotesJobTypeFilter
                  }
                  setNewNoteKind={setNewNoteKind}
                  setNewNotesText={setNewNotesText}
                  setNewNotesID={setNewNotesID}
                  setNewNote={setNewNote}
                  firebase={firebase}
                  jobData={jobData}
                  clientData={clientData}
                  clientTab={clientTab}
                  shootTab={shootTab}
                />
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

function isJobTypeMatching(jobTypes, noteJobTypes, jobType) {
  let res = false;
  if (!noteJobTypes) {
    return true;
  }
  if (noteJobTypes.length === 0) {
    return true;
  }

  jobTypes.forEach((jt) => {
    const combined = `${jt.name} (-${jt.nameShort})`;
    if (combined === jobType) {
      noteJobTypes.forEach((njt) => {
        if (jt.id === njt) res = true;
      });
    }
  });
  return res;
}

function shouldShow(kind, note, isAdmin, jobTypes, jobData) {
  const matching = isJobTypeMatching(
    jobTypes,
    note.jobTypes,
    jobData?.jobTypeFull
  );
  if (!matching) {
    if (kind === "ShowAll") return true;
    return false;
  }

  if (kind !== note.kind) {
    if (kind === "ShowAll") return true;
    return false;
  }
  if (note.tags.includes("Pre-Production")) {
    if (!isAdmin) return false;
  }
  return true;
}

export default NotesContainer;

const NoteContainer = ({
  index,
  jobTab,
  note,
  firebase,
  clientData,
  selectedNotesFilter,
  isMobile,
  modalWidth,
  theme,
  setNewNoteModalOpen,
  setNewSelectedNotesFilter,
  setNewSelectedNotesJobTypeFilter,
  setNewNoteKind,
  setNewNotesText,
  setNewNotesID,
  setNewNote,
  jobData,
  clientTab,
  shootTab,
}) => {
  const [showNote, setShowNote] = useState(false);
  const [jobTypes] = useAtom(jobTypesAtom);

  useEffect(() => {
    if (selectedNotesFilter.length === 0) {
      setShowNote(true);
    } else {
      let tmp = false;
      selectedNotesFilter.forEach((nf) => {
        if (note.tags.includes(nf)) {
          tmp = true;
        }
      });
      setShowNote(tmp);
    }
  }, [selectedNotesFilter, setShowNote, note]);

  const [contextMenu, setContextMenu] = useState(null);
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };
  const handleDeleteNoteClick = () => {
    handleClose();
    if (shootTab) return;

    if (note.kind === "Permanent") {
      let theNote = null;
      clientData.notes.forEach((n, index) => {
        if (n.id === note.id) {
          theNote = index;
        }
      });
      if (theNote !== null) {
        clientData.notes.splice(theNote, 1);
        firebase
          .firestore()
          .collection("ClientDatabase")
          .doc(clientData.id)
          .update({ notes: clientData.notes });
      }
    }
    if (note.kind === "Job") {
      let theNote = null;
      jobData.notes.forEach((n, index) => {
        if (n.id === note.id) {
          theNote = index;
        }
      });
      if (theNote !== null) {
        jobData.notes.splice(theNote, 1);
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({ notes: jobData.notes });
      }
    }
  };
  const tagChipStyle = {
    borderRadius: 1,
    backgroundColor:
      note.kind === "Permanent"
        ? theme === "dark"
          ? "rgba(130, 165, 200, 0.4)"
          : "rgba(148, 195, 245, 0.3)"
        : theme === "dark"
        ? "rgba(130, 200, 165, 0.4)"
        : "rgba(148, 245, 195, 0.3)",
    fontWeight: "bold",
  };
  const jobTypeChipStyle = {
    borderRadius: 1,
    backgroundColor:
      note.kind === "Permanent"
        ? theme === "dark"
          ? "rgba(130, 165, 200, 0.25)"
          : "rgba(148, 195, 245, 0.20)"
        : theme === "dark"
        ? "rgba(130, 200, 165, 0.25)"
        : "rgba(148, 245, 195, 0.20)",
  };
  const noteTagContainer = {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  };
  const topTagsContainer = {
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor:
      note.kind === "Permanent"
        ? theme === "dark"
          ? "rgba(130, 165, 200, 0.06)"
          : "rgba(148, 195, 245, 0.1)"
        : theme === "dark"
        ? "rgba(130, 200, 165, 0.06)"
        : "rgba(148, 245, 195, 0.1)",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: "flex",
    justifyContent: "space-between",
  };
  const bottomTagsContainer = {
    paddingTop: 20,
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor:
      note.kind === "Permanent"
        ? theme === "dark"
          ? "rgba(130, 165, 200, 0.06)"
          : "rgba(148, 195, 245, 0.1)"
        : theme === "dark"
        ? "rgba(130, 200, 165, 0.06)"
        : "rgba(148, 245, 195, 0.1)",
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  };
  return (
    <div style={{ width: "100%" }}>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem key={"deleteNoteButton"} onClick={handleDeleteNoteClick}>
          Delete Note
        </MenuItem>
      </Menu>
      {!jobTab && !note.text ? null : (
        <div
          style={{
            cursor: jobTab ? "pointer" : "",
            display: showNote ? "block" : "none",
            marginLeft: clientTab ? 20 : 0,
          }}
          onClick={() => {
            try {
              setNewNoteModalOpen(true);
              setNewSelectedNotesFilter(note.tags);
              setNewSelectedNotesJobTypeFilter(note.jobTypes);
              setNewNoteKind(note.kind);
              setNewNotesText(note.text);
              setNewNotesID(note.id);
              setNewNote(false);

              const scrollDiv =
                document.getElementById("editNoteContainer").offsetTop;
              document.getElementById("eventDataModal").scrollTop = scrollDiv;
            } catch (e) {
              console.log(e);
            }
          }}
          onContextMenu={shootTab ? () => {} : handleContextMenu}
        >
          <div style={topTagsContainer}>
            <div style={noteTagContainer}>
              {note &&
                note.tags.map((tag, index) => (
                  <Chip
                    sx={tagChipStyle}
                    size="small"
                    label={tag}
                    key={`tags_${index}`}
                  />
                ))}
            </div>
            <Chip
              sx={{ ...tagChipStyle, marginLeft: 1.3 }}
              size="small"
              label={note.kind}
              key={`tags_`}
            />
          </div>
          <div style={bottomTagsContainer}>
            <div style={noteTagContainer}>
              {note.jobTypes &&
                note.jobTypes.map((type, index) => {
                  const jt = jobTypes.find((obj) => obj.id === type);
                  const combined = `${jt.name} (-${jt.nameShort})`;
                  return (
                    <Chip
                      sx={jobTypeChipStyle}
                      size="small"
                      label={combined}
                      key={`tags_${index}`}
                    />
                  );
                })}
            </div>
          </div>
          <MDEditor.Markdown
            source={note.text}
            style={{
              padding: 15,
              width: "100%",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              backgroundColor:
                note.kind === "Permanent"
                  ? theme === "dark"
                    ? "rgba(130, 165, 200, 0.06)"
                    : "rgba(148, 195, 245, 0.1)"
                  : theme === "dark"
                  ? "rgba(130, 200, 165, 0.06)"
                  : "rgba(148, 245, 195, 0.1)",
              color:
                theme === "dark" ? "rgb(220, 220, 220)" : "rgb(30, 30, 30)",
            }}
          />
        </div>
      )}
    </div>
  );
};
