import React, { useState, useRef, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const CodeInput = ({ firebase, theme, isElectron }) => {
  const [code, setCode] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const inputsRef = useRef([]);

  const auth = getAuth();

  const generateLoginCodeToken = firebase
    .functions()
    .httpsCallable("generateLoginCodeToken");

  useEffect(() => {
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, []);

  // Auto-focus the first input when there's an error
  useEffect(() => {
    if (error && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [error]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      setError(false);

      if (value && index < 3) {
        inputsRef.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        // Move to previous input if the current input is cleared
        inputsRef.current[index - 1]?.focus();
      }

      if (index === 3 && value) {
        handleSubmit(newCode.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowLeft" && index > 0) {
      inputsRef.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < 3) {
      inputsRef.current[index + 1]?.focus();
    } else if (e.key === "Backspace" || e.key === "Delete") {
      if (!code[index] && index > 0) {
        const newCode = [...code];
        newCode[index - 1] = "";
        setCode(newCode);
        inputsRef.current[index - 1]?.focus();
      } else if (e.key === "Backspace" && code[index]) {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      }
    }
  };

  const handleSubmit = async (code) => {
    setLoading(true);
    try {
      const loginToken = await generateLoginCodeToken({ loginCode: code });
      if (loginToken.data.error) {
        setError(true);
        clearInputs();
      } else if (loginToken.data.token) {
        if (isElectron) {
          await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
          // .setPersistence(firebase.auth.Auth.Persistence.SESSION);
        } else {
          await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        }
        await signInWithCustomToken(auth, loginToken.data.token);
        window?.ipcRenderer?.send("sendSwiftLogin", loginToken.data.token);

        console.log("Signed in with custom token");
        clearInputs();
      } else {
        console.log("Unexpected response:", loginToken);
        setError(true);
        clearInputs();
      }
    } catch (error) {
      console.error("Error generating login code token:", error);
      setError(true);
      clearInputs();
    } finally {
      setLoading(false);
    }
  };

  const clearInputs = () => {
    setCode(["", "", "", ""]);
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  };

  const inputStyle = {
    width: "50px",
    height: "50px",
    textAlign: "center",
    fontSize: "24px",
    borderRadius: "8px",
    border: `2px solid ${error ? "red" : theme === "dark" ? "#fff" : "#000"}`,
    backgroundColor: "transparent",
    color: theme === "dark" ? "#fff" : "#000",
    outline: "none",
    boxShadow: "none",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {loading ? (
        <CircularProgress color="primary" size={50} />
      ) : (
        code.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            maxLength="1"
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
            style={inputStyle}
          />
        ))
      )}
    </div>
  );
};

export default CodeInput;
