import React, { useState, useEffect, useRef, useMemo } from "react";
import { use100vh } from "react-div-100vh";
import { createGlobalStyle } from "styled-components";
import Chip from "@mui/material/Chip";
import { Virtuoso } from "react-virtuoso";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChecklistIcon from "@mui/icons-material/Checklist";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, Box } from "@mui/system";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Button,
  Tooltip,
  ListItemIcon,
  Avatar,
  AvatarGroup,
  Collapse,
  List as MUIList,
  ListItemButton,
  ListItemText,
  ListItem,
  Checkbox,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import { getFirestore } from "firebase/firestore";
import { onSnapshot, doc, getDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import LabelChip from "../GlobalComponents/LabelChip";
import PermanentLabelChip from "../GlobalComponents/PermanentLabelChip";
import CloseIcon from "@mui/icons-material/Close";
import DueDateChip from "../GlobalComponents/DueDateChip";
import ImagesChip from "../GlobalComponents/ImagesChip";
import ShootTab from "../Calendar/Event Data Modal Components/ShootTab";
import ClientTab from "../Calendar/Event Data Modal Components/ClientTab";
import KanbanClientSelector from "./KanbanClientSelector";
import KanbanDueDateExpressContainer from "./KanbanDueDateExpressContainer";
import KanbanJobNumber from "./KanbanJobNumber";
import KanbanJobTypeSelector from "./KanbanJobTypeSelector";
import KanbanSpecialRequirementsContainer from "./KanbanSpecialRequirementsContainer";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useHistory, useLocation } from "react-router-dom";
import "./kanban.css";
import { useAtom } from "jotai";
import {
  isIdleAtom,
  themeAtom,
  listsAtom,
  labelColoursAtom,
  staffDataAtom,
  currentStaffDataAtom,
  newJobObjectAtom,
  isAdminAtom,
  isDebouncingAtom,
  jobTypesAtom,
} from "../-Atoms";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import getJobName from "../GlobalFunctions/getJobName";
const { DateTime } = require("luxon");

const GlobalStyles = createGlobalStyle``;

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Kanban({ firebase, user }) {
  const [isIdle] = useAtom(isIdleAtom);
  const [theme] = useAtom(themeAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [isDebouncing] = useAtom(isDebouncingAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [lists, setLists] = useAtom(listsAtom);
  const [staffData] = useAtom(staffDataAtom);
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [clientData, setClientData] = useState({});
  const isMobile = useMediaQuery("@media (max-width:780px)");
  const modal = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalJobData, setModalJobData] = useState({});
  const [modalClientData, setModalClientData] = useState({});
  const modalWidth = isMobile ? "100vw" : "780px";
  const db = getFirestore(firebase.app());

  // New state for recentlyAssignedStaff
  const [recentlyAssignedStaff, setRecentlyAssignedStaff] = useState([]);

  // Use a snapshot listener to update recentlyAssignedStaff when it changes in Firebase
  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("AdminCollection")
      .doc("recentlyAssignedStaff")
      .onSnapshot((doc) => {
        if (doc.exists) {
          setRecentlyAssignedStaff(doc.data().recentlyAssignedStaff || []);
        } else {
          setRecentlyAssignedStaff([]);
        }
      });
    return () => unsub();
  }, [firebase]);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot((collection) => {
        const cd = collection.docs.map((client) => {
          const data = client.data();
          data.id = client.id;
          return data;
        });
        setClientData([...cd]);
      });
    return () => {
      unsub();
    };
  }, [firebase, setClientData, isIdle]);

  async function onDragEnd(e) {
    if (!e?.destination?.droppableId) return;

    if (e.source.droppableId === e.destination.droppableId) {
      let theList;
      lists.forEach((list) => {
        if (list.id === e.source.droppableId) {
          theList = list;
        }
      });
      if (!theList) {
        console.log("cant find list");
        return;
      }
      theList.items = reorder(
        theList.items,
        e.source.index,
        e.destination.index
      );
      setLists(lists);
      await firebase
        .firestore()
        .collection("AdminCollection")
        .doc("lists")
        .update({ lists: lists });
    } else {
      let sourceList;
      let destList;
      lists.forEach((list) => {
        if (list.id === e.source.droppableId) {
          sourceList = list;
        }
        if (list.id === e.destination.droppableId) {
          destList = list;
        }
      });
      if (!sourceList) {
        console.log("cant find source list");
        return;
      }
      if (!destList) {
        console.log("cant find destination list");
        return;
      }
      const idJob = sourceList.items[e.source.index];
      sourceList.items.splice(e.source.index, 1);
      if (!destList.items.includes(idJob)) {
        destList.items.splice(e.destination.index, 0, idJob);
      }
      setLists(lists);
      await firebase
        .firestore()
        .collection("AdminCollection")
        .doc("lists")
        .update({ lists: lists });

      if (e.destination.droppableId === "5f081763cd97556d03075c86") {
        firebase
          .firestore()
          .collection("JobData")
          .doc(e.draggableId)
          .update({
            isInShooting: true,
            isInShootingTime: DateTime.now()
              .setZone("Australia/Sydney")
              .startOf("day")
              .toUTC()
              .toISO(),
          });
      } else {
        firebase
          .firestore()
          .collection("JobData")
          .doc(e.draggableId)
          .update({ isInShooting: false, isInShootingTime: null });
      }

      if (
        e.destination.droppableId === "0367cb77-644f-4d57-b4e8-4286148218d7"
      ) {
        const docSnap = await getDoc(doc(db, "JobData", e.draggableId));
        if (!docSnap.exists()) return;
        const jobData = docSnap.data();
        if (jobData.idLabels.indexOf("6285ea079131e28982c19d39") === -1) {
          jobData.idLabels.push("6285ea079131e28982c19d39");
        }
        firebase
          .firestore()
          .collection("JobData")
          .doc(e.draggableId)
          .update({ idLabels: jobData.idLabels });
      }

      if (
        e.destination.droppableId === "bbb73c17-6690-4d43-8569-be12b0d5ee27"
      ) {
        const docSnap = await getDoc(doc(db, "JobData", e.draggableId));
        if (!docSnap.exists()) return;
        const jobData = docSnap.data();
        if (jobData.idLabels.indexOf("5e6881d6af988c41f2bf6e14") === -1) {
          jobData.idLabels.push("5e6881d6af988c41f2bf6e14");
        }
        firebase
          .firestore()
          .collection("JobData")
          .doc(e.draggableId)
          .update({ idLabels: jobData.idLabels });
      }
    }
  }

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const height = use100vh();

  const style = (themeMui) => ({
    width: modalWidth,
    outline: "none",
  });

  useEffect(() => {
    const jobID = query.get("jobID");
    const clientID = query.get("clientID");

    if (jobID && clientID) {
      setModalJobData({ id: jobID });
      setModalClientData({ id: clientID });
      setModalOpen(true);
    } else {
      setModalOpen(false);
      setModalJobData({});
      setModalClientData({});
    }
  }, [query]);

  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const [stopModalClose, setStopModalClose] = useState(false);

  // Function to update recentlyAssignedStaff in Firebase
  const updateRecentlyAssignedStaff = async (staffId) => {
    let updatedList = [
      staffId,
      ...recentlyAssignedStaff.filter((id) => id !== staffId),
    ];
    if (updatedList.length > 5) updatedList = updatedList.slice(0, 5);

    // Update the local state
    setRecentlyAssignedStaff(updatedList);

    // Update in Firebase
    await firebase
      .firestore()
      .collection("AdminCollection")
      .doc("recentlyAssignedStaff")
      .set({ recentlyAssignedStaff: updatedList }, { merge: true });
  };

  return (
    <>
      <GlobalStyles />
      <StyledModal
        open={modalOpen}
        onClose={() => {
          if (stopModalClose) {
            console.log("NO CLOSE");
          }
          if (isDebouncing) return;
          if (!stopModalClose) {
            setModalOpen(false);
            setModalJobData({});
            setModalClientData({});
            history.push(location.pathname);
          }
        }}
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box sx={style} ref={modal}>
          <div
            style={{
              background: theme === "dark" ? "#1f1f1f" : "#ffffff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 10,
              position: "relative",
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 6, right: 10, zIndex: 99999 }}
              onClick={() => {
                if (stopModalClose) {
                  console.log("NO CLOSE");
                }
                if (isDebouncing) return;
                if (!stopModalClose) {
                  setModalOpen(false);
                  setModalJobData({});
                  setModalClientData({});
                  history.push(location.pathname);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            {isAdmin ? (
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{ marginBottom: 1, padding: "0px 8px 8px 8px" }}
              >
                <Tab label="Job" disabled={isDebouncing} />
                <Tab label="Client" disabled={isDebouncing} />
              </Tabs>
            ) : (
              <div style={{ height: 30 }}>&zwnj;</div>
            )}
            <TabPanel value={value} index={0}>
              <ShootTab
                firebase={firebase}
                jobData={modalJobData}
                setJobData={setModalJobData}
                clientDataInit={{}}
                staffData={staffData}
                theme={theme}
                modalWidth={modalWidth}
                isMobile={isMobile}
                currentUser={currentStaffData}
                setStopModalClose={setStopModalClose}
                kanbanView={true}
                element={modal}
                soloClose={true}
                modalJobData={modalJobData}
                modalClientData={modalClientData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ overflow: "scroll" }}>
                <ClientTab
                  jobClientData={{}}
                  firebase={firebase}
                  theme={theme}
                  modalWidth={modalWidth}
                  modalClientData={modalClientData}
                  soloClose={true}
                  kanbanView={true}
                />
              </div>
            </TabPanel>
          </div>
        </Box>
      </StyledModal>
      <div
        id="board"
        style={{
          overflowY: "hidden",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {lists[0]?.items &&
            lists?.map((list) => (
              <>
                {list?.items?.length > 50 ? (
                  <VirtualList
                    key={list.id}
                    firebase={firebase}
                    list={list}
                    height={height}
                    clientData={clientData}
                    setModalOpen={setModalOpen}
                    setModalJobData={setModalJobData}
                    setModalClientData={setModalClientData}
                    recentlyAssignedStaff={recentlyAssignedStaff}
                    updateRecentlyAssignedStaff={updateRecentlyAssignedStaff}
                  />
                ) : (
                  <List
                    key={list.id}
                    firebase={firebase}
                    user={user}
                    list={list}
                    height={height}
                    clientData={clientData}
                    setModalOpen={setModalOpen}
                    setModalJobData={setModalJobData}
                    setModalClientData={setModalClientData}
                    recentlyAssignedStaff={recentlyAssignedStaff}
                    updateRecentlyAssignedStaff={updateRecentlyAssignedStaff}
                  />
                )}
              </>
            ))}
        </DragDropContext>
      </div>
    </>
  );
}
export default Kanban;

const nameStyle = {
  display: "block",
  fontWeight: "bold",
  fontSize: 14,
  margin: 4,
  marginLeft: 12,
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
};

const itemLengthStyle = {
  position: "absolute",
  top: 8,
  right: 14,
  fontWeight: "bold",
  opacity: 0.1,
};

const itemLengthStyleButton = {
  position: "absolute",
  top: 0,
  right: 0,
  fontWeight: "bold",
  opacity: 0.3,
};

const List = ({
  firebase,
  user,
  list,
  clientData,
  setModalOpen,
  setModalJobData,
  setModalClientData,
  recentlyAssignedStaff,
  updateRecentlyAssignedStaff,
}) => {
  const [theme] = useAtom(themeAtom);
  const [lists, setLists] = useAtom(listsAtom);
  const [labelColours] = useAtom(labelColoursAtom);
  const [listBackgroundColour, setlistBackgroundColour] = useState(
    theme === "dark" ? "#101010" : "#f5f5f5"
  );
  const [showListOptions, setShowListOptions] = useState(false);

  const [listOptionsMenuOpen, setListOptionsMenuOpen] = useState(null);
  const listOptionsMenuAnchor = useRef(null);

  const [newJobObject] = useAtom(newJobObjectAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);

  const handleListOptionsClick = (event) => {
    setListOptionsMenuOpen(true);
  };

  const handleListOptionsClose = () => {
    setListOptionsMenuOpen(false);
  };


  useEffect(() => {
    const main = async () => {
      const parseListBackgroundColour = (days) => {
        if (days === 0) return theme === "dark" ? "#101010" : "#f5f5f5";
        if (days === 1)
          return labelColours
            ? labelColours["sky"][theme]
            : theme === "dark"
            ? "#376972"
            : "#7BC4D0";
        if (days === 2)
          return labelColours
            ? labelColours["yellow"][theme]
            : theme === "dark"
            ? "#7A601D"
            : "#dbce3d";
        if (days >= 3)
          return labelColours
            ? labelColours["red"][theme]
            : theme === "dark"
            ? "#A03624"
            : "#d44e3f";
    
        return theme === "dark" ? "#101010" : "#f5f5f5";
      };


      if (list.id !== "5f081763cd97556d03075c86") {
        setlistBackgroundColour(theme === "dark" ? "#101010" : "#f5f5f5");
        return;
      }
      let highest = 0;
      for (let i = 0; i < list.items.length; i++) {
        const job = await firebase
          .firestore()
          .collection("JobData")
          .doc(list.items[i])
          .get();

        const difference = getDaysDifference(
          job.data().isInShootingTime,
          DateTime.now()
            .setZone("Australia/Sydney")
            .startOf("day")
            .toUTC()
            .toISO()
        );
        if (difference > highest) highest = difference;
      }
      setlistBackgroundColour(parseListBackgroundColour(highest));
    };
    main();
    const interval = setInterval(main, 60000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [list, theme, labelColours, firebase]);

  const menuItemStyle = {
    fontSize: 14,
  };
  const sortByDueDate = async () => {
    handleListOptionsClose();
    const listObj = [];
    for (let i = 0; i < list.items.length; i++) {
      const job = await firebase
        .firestore()
        .collection("JobData")
        .doc(list.items[i])
        .get();
      const unix = DateTime.fromISO(job.data().dueDate)
        .setZone("Australia/Sydney")
        .valueOf();
      listObj.push({ id: list.items[i], dd: unix });
    }
    listObj.sort((a, b) => a.dd - b.dd);
    const newListArr = [];
    listObj.forEach((li) => {
      newListArr.push(li.id);
    });
    list.items = newListArr;
    setLists(lists);
    firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });
  };
  const sortByJobNumber = async () => {
    handleListOptionsClose();
    const listObj = [];
    for (let i = 0; i < list.items.length; i++) {
      const job = await firebase
        .firestore()
        .collection("JobData")
        .doc(list.items[i])
        .get();
      listObj.push({ id: list.items[i], jobNumber: job.data().jobNumber });
    }
    listObj.sort((a, b) => a.jobNumber - b.jobNumber);
    const newListArr = [];
    listObj.forEach((li) => {
      newListArr.push(li.id);
    });
    list.items = newListArr;
    setLists(lists);
    firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });
  };

  const [newPostJobModalOpen, setNewPostJobModalOpen] = useState(false);

  const modalStyle = (themeMui) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 670,
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#fafafa",
    outline: "none",
    boxShadow: 18,
    padding: 2,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  });

  const [newPostJobErrorDialogOpen, setNewPostJobErrorDialogOpen] =
    useState(false);
  const handleNewPostJobErrorDialogClose = () =>
    setNewPostJobErrorDialogOpen(false);

  const [newPostJobJobNumber, setNewPostJobJobNumber] = useState("");
  const [newPostJobJobType, setNewPostJobJobType] = useState("");
  const [newPostJobMultipleDays, setNewPostJobMultipleDays] = useState("");
  const [newPostJobClient, setNewPostJobClient] = useState("");
  const [newPostJobExpress, setNewPostJobExpress] = useState(false);
  const [newPostJobDueDate, setNewPostJobDueDate] = useState(null);
  const [newPostJobExpressDueDate, setNewPostJobExpressDueDate] =
    useState(null);
  const [
    newPostJobInvoicingSpecialRequirements,
    setNewPostJobInvoicingSpecialRequirements,
  ] = useState("");
  const [
    newPostJobRetouchingSpecialRequirements,
    setNewPostJobRetouchingSpecialRequirements,
  ] = useState("");

  const addNewPostJob = async () => {
    let shouldContinue = true;
    if (!newPostJobJobNumber) shouldContinue = false;
    if (!newPostJobJobType) shouldContinue = false;
    if (!newPostJobClient) shouldContinue = false;
    if (!newPostJobDueDate) shouldContinue = false;

    if (!shouldContinue) {
      setNewPostJobErrorDialogOpen(true);
      return;
    }
    const jts = newPostJobJobType.match(/^.*\((-.*)\)/)[1];
    const jn = `${newPostJobJobNumber}-${newPostJobClient}${
      newPostJobMultipleDays ? `-${newPostJobMultipleDays}` : ""
    }${jts}`;

    const newJobInfo = {
      ...newJobObject,
      client: newPostJobClient,
      jobNumber: parseInt(newPostJobJobNumber),
      multipleDays: newPostJobMultipleDays,
      jobName: jn,
      express: newPostJobExpress,
      dueDate: newPostJobDueDate,
      expressDueDate: newPostJobExpressDueDate,
      invoicingSpecialRequirements: newPostJobInvoicingSpecialRequirements,
      retouchingSpecialRequirements: newPostJobRetouchingSpecialRequirements,
      allDay: false,
      end: "",
      start: "",
      shootDate: null,
      bookedStart: null,
      bookedFinish: null,
      studio: "",
      year: new Date().getFullYear(),
      jobTypeFull: newPostJobJobType,
      jobTypeShort: newPostJobJobType.match(/^.*\((-.*)\)/)[1],
      imageBreakdown: [
        {
          id: uuidv4(),
          description: "File Count",
          jobTypesFilter: [],
          expectedPerHour: "",
          costPerFile: "",
          existingItem: true,
        },
      ],
      tracking: {
        created: {
          by: currentStaffData.id,
          at: new Date().toISOString(),
        },
        modified: [],
        trelloCardModified: [],
      },
      checklists: [
        {
          name: "Colour Corrections",
          id: uuidv4(),
          checklistItems: [],
        },
        {
          name: "Colour Changes",
          id: uuidv4(),
          checklistItems: [],
        },
        {
          name: "Retouching Notes",
          id: uuidv4(),
          checklistItems: [],
        },
      ],
    };
    firebase
      .firestore()
      .collection("JobData")
      .add(newJobInfo)
      .then((doc) => {
        setNewPostJobModalOpen(false);
        list.items.push(doc.id);
        const noDupes = [...new Set(list.items)];
        list.items = noDupes;

        firebase
          .firestore()
          .collection("AdminCollection")
          .doc("lists")
          .update({ lists: lists });

        setNewPostJobJobNumber("");
        setNewPostJobJobType("");
        setNewPostJobMultipleDays("");
        setNewPostJobClient("");
        setNewPostJobExpress(false);
        setNewPostJobDueDate(null);
        setNewPostJobExpressDueDate(null);

        return doc.id;
      });
  };

  return (
    <>
      <Dialog
        open={newPostJobErrorDialogOpen}
        onClose={handleNewPostJobErrorDialogClose}
      >
        <DialogContent>
          <DialogContentText>
            Please ensure the following fields are filled out:
          </DialogContentText>
          <DialogContentText>Client</DialogContentText>
          <DialogContentText>Due Date</DialogContentText>
          <DialogContentText>Job Number</DialogContentText>
          <DialogContentText>Job Type</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewPostJobErrorDialogClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <StyledModal
        open={newPostJobModalOpen}
        onClose={() => {
          setNewPostJobModalOpen(false);
        }}
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box sx={modalStyle}>
          <span style={{ fontWeight: "bold" }}>New Post Job</span>
          <KanbanClientSelector
            clientData={clientData}
            newPostJobClient={newPostJobClient}
            setNewPostJobClient={setNewPostJobClient}
            newPostJobDueDate={newPostJobDueDate}
            setNewPostJobDueDate={setNewPostJobDueDate}
          />
          <KanbanDueDateExpressContainer
            newPostJobDueDate={newPostJobDueDate}
            setNewPostJobDueDate={setNewPostJobDueDate}
            newPostJobExpress={newPostJobExpress}
            setNewPostJobExpress={setNewPostJobExpress}
            newPostJobExpressDueDate={newPostJobExpressDueDate}
            setNewPostJobExpressDueDate={setNewPostJobExpressDueDate}
          />
          <KanbanJobNumber
            newPostJobJobNumber={newPostJobJobNumber}
            setNewPostJobJobNumber={setNewPostJobJobNumber}
          />
          <KanbanJobTypeSelector
            newPostJobJobType={newPostJobJobType}
            setNewPostJobJobType={setNewPostJobJobType}
            newPostJobMultipleDays={newPostJobMultipleDays}
            setNewPostJobMultipleDays={setNewPostJobMultipleDays}
          />
          <KanbanSpecialRequirementsContainer
            value={newPostJobInvoicingSpecialRequirements}
            setValue={setNewPostJobInvoicingSpecialRequirements}
            label="Invoicing Special Requirements"
          />
          <KanbanSpecialRequirementsContainer
            value={newPostJobRetouchingSpecialRequirements}
            setValue={setNewPostJobRetouchingSpecialRequirements}
            label="Retouching Special Requirements"
          />
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={addNewPostJob}
          >
            Create Job
          </Button>
        </Box>
      </StyledModal>
      <Menu
        anchorEl={listOptionsMenuAnchor?.current}
        keepMounted
        open={listOptionsMenuOpen}
        onClose={handleListOptionsClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        disableScrollLock={true}
        PaperProps={{
          style: {
            width: 200,
            background: theme === "dark" ? "#141414" : "#dbdbdb",
          },
        }}
      >
        <div style={{ paddingTop: 2, paddingLeft: 15, paddingRight: 15 }}>
          <span
            style={{ fontWeight: "bold", marginBottom: 10, display: "block" }}
          >
            Sort By:
          </span>
          <Divider sx={{ margin: 1 }} />
          <MenuItem sx={menuItemStyle} onClick={sortByDueDate}>
            Due Date
          </MenuItem>
          <Divider />
          <MenuItem sx={menuItemStyle} onClick={sortByJobNumber}>
            Job Number
          </MenuItem>
          <div style={{ margin: 5 }}></div>
        </div>
      </Menu>

      <div
        style={{
          backgroundColor: listBackgroundColour,
          position: "relative",
        }}
        className="list-wrapper"
      >
        <span style={nameStyle} className="list-header">
          {list.name}
        </span>
        <div
          onMouseEnter={() => setShowListOptions(true)}
          onMouseLeave={() => setShowListOptions(false)}
        >
          {showListOptions ? (
            <span style={itemLengthStyleButton} ref={listOptionsMenuAnchor}>
              <IconButton onClick={handleListOptionsClick}>
                <MoreHorizIcon />
              </IconButton>
            </span>
          ) : (
            <span style={itemLengthStyle}>{list.items.length}</span>
          )}
        </div>
        <Droppable
          droppableId={list.id}
          style={{ overflowY: "scroll" }}
          renderClone={(provided, snapshot, rubric) => (
            <Card
              provided={provided}
              isDragging={snapshot.isDragging}
              item={list.items[rubric.source.index]}
              items={list}
              firebase={firebase}
              clientData={clientData}
              clone={false}
              setModalOpen={setModalOpen}
              setModalJobData={setModalJobData}
              setModalClientData={setModalClientData}
              listId={list.id}
              recentlyAssignedStaff={recentlyAssignedStaff}
              updateRecentlyAssignedStaff={updateRecentlyAssignedStaff}
            />
          )}
        >
          {(provided) => {
            return (
              <div
                className="list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div style={{ width: "100%", height: 5 }}></div>
                <div className="list-cards">
                  {list.items.map((item, index) => (
                    <Draggable draggableId={item} index={index} key={item}>
                      {(provided, snapshot) => (
                        <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                          <Card
                            provided={provided}
                            isDragging={snapshot.isDragging}
                            item={item}
                            items={list.items}
                            firebase={firebase}
                            clientData={clientData}
                            clone={false}
                            setModalOpen={setModalOpen}
                            setModalJobData={setModalJobData}
                            setModalClientData={setModalClientData}
                            listId={list.id}
                            recentlyAssignedStaff={recentlyAssignedStaff}
                            updateRecentlyAssignedStaff={
                              updateRecentlyAssignedStaff
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            );
          }}
        </Droppable>
        {list.id === "5f08176c00570287a11c2272" ||
        list.id === "a68239ba-193d-4058-98f3-cdd4b2842efd" ? (
          <div
            className="addNewPostCard"
            style={{
              backgroundColor: theme === "dark" ? "#272727" : "#ebebeb",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (user) {
                setNewPostJobModalOpen(true);
              } else {
                alert("login in to make post card");
              }
            }}
          >
            <AddIcon sx={{ marginRight: 0.8, fontSize: 20 }} />
            <span>New Post Card</span>
          </div>
        ) : null}
      </div>
    </>
  );
};

const VirtualList = ({
  firebase,
  list,
  clientData,
  setModalOpen,
  setModalJobData,
  setModalClientData,
  recentlyAssignedStaff,
  updateRecentlyAssignedStaff,
}) => {
  const [theme] = useAtom(themeAtom);

  return (
    <div
      style={{
        backgroundColor: theme === "dark" ? "#101010" : "#f5f5f5",
        position: "relative",
      }}
      className="list-wrapper-virtual"
    >
      <span style={nameStyle} className="list-header">
        {list.name}
      </span>
      <span style={itemLengthStyle}>{list.items.length}</span>
      <div className="list-virtual">
        <Droppable
          droppableId={list.id}
          style={{ overflowY: "scroll" }}
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <Card
              provided={provided}
              isDragging={snapshot.isDragging}
              item={list.items[rubric.source.index]}
              items={list}
              firebase={firebase}
              clientData={clientData}
              clone={false}
              setModalOpen={setModalOpen}
              setModalJobData={setModalJobData}
              setModalClientData={setModalClientData}
              listId={list.id}
              recentlyAssignedStaff={recentlyAssignedStaff}
              updateRecentlyAssignedStaff={updateRecentlyAssignedStaff}
            />
          )}
        >
          {(provided, snapshot) => {
            return (
              <Virtuoso
                components={{
                  Item: HeightPreservingItem,
                }}
                scrollerRef={provided.innerRef}
                data={list.items}
                className="list-cards"
                itemContent={(index, item) => {
                  return (
                    <Draggable
                      draggableId={item}
                      index={index}
                      key={`${item}-${index}`}
                    >
                      {(provided) => (
                        <Card
                          key={`${item}-Card-${index}`}
                          provided={provided}
                          isDragging={snapshot.isDragging}
                          item={item}
                          items={list.items}
                          firebase={firebase}
                          clientData={clientData}
                          clone={false}
                          setModalOpen={setModalOpen}
                          setModalJobData={setModalJobData}
                          setModalClientData={setModalClientData}
                          listId={list.id}
                          recentlyAssignedStaff={recentlyAssignedStaff}
                          updateRecentlyAssignedStaff={
                            updateRecentlyAssignedStaff
                          }
                        />
                      )}
                    </Draggable>
                  );
                }}
              />
            );
          }}
        </Droppable>
      </div>
    </div>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  justify-content: center;
`;

function Card({
  provided,
  item,
  isDragging,
  firebase,
  clientData,
  clone,
  setModalOpen,
  setModalJobData,
  setModalClientData,
  listId,
  recentlyAssignedStaff,
  updateRecentlyAssignedStaff,
}) {
  const [theme] = useAtom(themeAtom);
  const [labelColours] = useAtom(labelColoursAtom);
  const [lists, setLists] = useAtom(listsAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [staffData] = useAtom(staffDataAtom);

  const history = useHistory();

  const db = getFirestore(firebase.app());

  const [jobData, setJobData] = useState({});

  useEffect(() => {
    let unsub;
    const main = () => {
      if (!item) return;
      unsub = onSnapshot(
        doc(db, "JobData", item),
        (doc) => {
          let data = doc.data();
          if (doc.id) {
            data.id = doc.id;
          }
          setJobData(data);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    main();
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
  }, [db, item]);

  const cardStyle = {
    display: clone ? "none" : "block",
    cursor: "pointer",
    marginLeft: 4,
    padding: 6,
  };
  const nameStyle = {
    display: "block",
    fontWeight: "bold",
    fontSize: 12,
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  };
  const bottomChipsStyle = {
    marginTop: 2,
    marginLeft: -2,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 4,
  };

  const [jobClientData, setJobClientData] = useState({});

  useEffect(() => {
    if (!jobData) return;
    if (!jobData.client) return;
    if (!clientData) return;
    try {
      clientData.forEach((c) => {
        if (c.brand === jobData.client) setJobClientData(c);
      });
    } catch (e) {
      return;
    }
  }, [jobData, clientData]);

  const [cardBackgroundColour, setCardBackgroundColour] = useState(
    theme === "dark" ? "#272727" : "#ebebeb"
  );

  const [jobTypes] = useAtom(jobTypesAtom);

  useEffect(() => {
    const specialListIds = [
      "5f08176c00570287a11c2272",
      "a68239ba-193d-4058-98f3-cdd4b2842efd",
    ];
    const isSpecialList = specialListIds.includes(listId);
    const isDarkTheme = theme === "dark";
    const hasOutsourcers = jobData?.outsourcers?.length > 0;
    const hasIdLabels = Array.isArray(jobData.idLabels);
    const hasSpecificLabel =
      hasIdLabels && jobData.idLabels.includes("6285ea079131e28982c19d39");

    let color;

    if (hasIdLabels) {
      if (hasSpecificLabel) {
        if (labelColours.length !== 0) {
          color = newShade(
            labelColours["black"][theme],
            isDarkTheme ? -20 : 40
          );
        } else {
          color = newShade(
            isDarkTheme ? "#5C6772" : "#8790A0",
            isDarkTheme ? -20 : 20
          );
        }
      } else {
        if (hasOutsourcers) {
          color = isDarkTheme ? "#272727" : "#ebebeb";
        } else if (isSpecialList) {
          color = isDarkTheme ? "#382c2c" : "#e0cece";
        } else {
          color = isDarkTheme ? "#272727" : "#ebebeb";
        }
      }
    } else {
      if (hasOutsourcers) {
        color = isDarkTheme ? "#272727" : "#ebebeb";
      } else if (isSpecialList) {
        color = isDarkTheme ? "#382c2c" : "#e0cece";
      } else {
        color = isDarkTheme ? "#272727" : "#ebebeb";
      }
    }

    const today = DateTime.now()
      .startOf("day")
      .setZone("Australia/Sydney")
      .toUTC()
      .toISO();

    const outstanding =
      jobData.outsourcers?.some((o) => {
        if (o.dueDate) {
          const iso = DateTime.fromISO(o.dueDate)
            .startOf("day")
            .setZone("Australia/Sydney")
            .toUTC()
            .toISO();
          return today < iso && specialListIds.includes(listId);
        }
        return false;
      }) || false;

    if (outstanding) {
      color = isDarkTheme ? "#2c3638" : "#cedde0";
    }

    if (listId === "bbb73c17-6690-4d43-8569-be12b0d5ee27") {
      const jobImageBreakdown = jobData?.imageBreakdown || [];
      const clientImageBreakdown = clientData?.imageBreakdown || [];
      const combinedImageBreakdown = [
        ...jobImageBreakdown,
        ...clientImageBreakdown,
      ];

      const filteredImageBreakdowns = combinedImageBreakdown.filter(
        (item) =>
          !item.itemHidden &&
          !item.quantitySupplied &&
          isJobTypeMatching(jobTypes, item.jobTypesFilter, jobData?.jobTypeFull)
      );

      if (filteredImageBreakdowns.length > 0) {
        color = isDarkTheme ? "#382c2c" : "#e0cece";
      }
    }

    setCardBackgroundColour(color);
  }, [theme, jobData, clientData, labelColours, listId, jobTypes]);

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };

  const assignStaffMenuRef = useRef(null);
  const [assignStaffMenuOpen, setAssignStaffMenuOpen] = useState(false);

  const handleClose = () => {
    setContextMenu(null);
    setAssignStaffMenuOpen(false);
  };

  const toggleHold = () => {
    handleClose();
    const holdID = "6285ea079131e28982c19d39";
    if (jobData?.idLabels.indexOf(holdID) !== -1) {
      jobData.idLabels.splice(jobData.idLabels.indexOf(holdID), 1);
    } else {
      jobData.idLabels.push(holdID);
    }
    const noDupes = [...new Set(jobData.idLabels)];
    jobData.idLabels = noDupes;
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ idLabels: jobData.idLabels });
  };

  const deleteCard = () => {
    console.log(item)
    handleClose();
    lists.forEach((list) => {
      if (list.items.indexOf(item) !== -1) {
        list.items.splice(list.items.indexOf(item), 1);
      }
    });
    setLists(lists);
    firebase
      .firestore()
      .collection("AdminCollection")
      .doc("lists")
      .update({ lists: lists });
  };

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{
          backgroundColor:
            theme === "dark"
              ? "rgba(10, 10, 10, 0.5)"
              : "rgba(240, 240, 240, 0.5)",
          borderRadius: 1,
        }}
      >
        <MenuItem
          onClick={() => {
            setAssignStaffMenuOpen((prev) => !prev);
          }}
          ref={assignStaffMenuRef}
        >
          Assign Staff
          {assignStaffMenuOpen ? (
            <ExpandLess sx={{ marginLeft: 1 }} />
          ) : (
            <ExpandMore sx={{ marginLeft: 1 }} />
          )}
        </MenuItem>
        <Collapse in={assignStaffMenuOpen} timeout="auto" unmountOnExit>
          <Divider />
          <MUIList
            component="div"
            disablePadding
            sx={{
              maxHeight: 600,
              overflowY: "auto",
            }}
          >
            {/* Recently Assigned Staff Section */}
            {recentlyAssignedStaff.length > 0 && (
              <>
                {recentlyAssignedStaff.map((staffId, index) => {
                  const staff = staffData.find((sd) => sd.id === staffId);
                  return staff ? (
                    <ListItem disablePadding key={`recent_${index}`}>
                      <ListItemButton
                        onClick={async () => {
                          try {
                            if (!jobData.hasOwnProperty("assignedStaff"))
                              jobData.assignedStaff = [];
                          } catch {
                            jobData.assignedStaff = [];
                          }

                          if (jobData?.assignedStaff?.includes(staff.id)) {
                            jobData.assignedStaff.splice(
                              jobData.assignedStaff.indexOf(staff.id),
                              1
                            );
                          } else {
                            jobData.assignedStaff.push(staff.id);
                          }

                          await firebase
                            .firestore()
                            .collection("JobData")
                            .doc(jobData.id)
                            .update({ assignedStaff: jobData.assignedStaff });

                          await updateRecentlyAssignedStaff(staff.id);
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={jobData?.assignedStaff?.includes(staff.id)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${staff.firstName} ${staff.lastName}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  ) : null;
                })}
                <Divider />
              </>
            )}
            {/* All Staff Section */}
            {staffData &&
              staffData
                .filter((sd) => !recentlyAssignedStaff.includes(sd.id))
                .map((sd, index) => (
                  <ListItem disablePadding key={`listItem_${index}`}>
                    <ListItemButton
                      onClick={async () => {
                        try {
                          if (!jobData.hasOwnProperty("assignedStaff"))
                            jobData.assignedStaff = [];
                        } catch {
                          jobData.assignedStaff = [];
                        }

                        if (jobData?.assignedStaff?.includes(sd.id)) {
                          jobData.assignedStaff.splice(
                            jobData.assignedStaff.indexOf(sd.id),
                            1
                          );
                        } else {
                          jobData.assignedStaff.push(sd.id);
                        }

                        await firebase
                          .firestore()
                          .collection("JobData")
                          .doc(jobData.id)
                          .update({ assignedStaff: jobData.assignedStaff });

                        await updateRecentlyAssignedStaff(sd.id);
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={jobData?.assignedStaff?.includes(sd.id)}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${sd.firstName} ${sd.lastName}`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
          </MUIList>
        </Collapse>
        <Divider />
        <Tooltip title="Toggles the hold tag and colour" placement="right">
          <MenuItem onClick={toggleHold}>Toggle Hold</MenuItem>
        </Tooltip>
        {isAdmin ? <Divider /> : null}
        {isAdmin ? (
          <Tooltip
            title="Will not delete any data, only the card"
            placement="right"
          >
            <MenuItem onClick={deleteCard}>Delete Card</MenuItem>
          </Tooltip>
        ) : null}
      </Menu>

      <div className="list-card" onContextMenu={handleContextMenu}>
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: cardBackgroundColour,
          }}
          className={`item ${isDragging ? "is-dragging" : ""}`}
        >
          {!getJobName(jobData) ? (
            <>
              <div
                style={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: 15,
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                }}
              >
                Loading...
              </div>
            </>
          ) : (
            <div
              style={cardStyle}
              className="card-click-class"
              onClick={(e) => {
                if (e.target.className === "card-click-class") {
                  setModalOpen(true);
                  setModalJobData(jobData);
                  setModalClientData(jobClientData);
                  history.push({
                    search: `?jobID=${jobData.id}&clientID=${jobClientData.id}`,
                  });
                }
              }}
            >
              {jobData.idLabelsPermanent &&
                jobData.idLabelsPermanent.map((label, index) => (
                  <PermanentLabelChip
                    key={`${label}-${index}`}
                    label={label}
                    kanbanBoard={true}
                    jobData={jobData}
                    firebase={firebase}
                  />
                ))}
              {jobData.idLabels &&
                jobData.idLabels.map((label, index) => (
                  <LabelChip
                    key={`${label}-${index}`}
                    label={label}
                    setLabelMenuOpen={() => {}}
                    kanbanBoard={true}
                  />
                ))}
              <span style={nameStyle} className="card-click-class">
                {getJobName(jobData)}
              </span>
              <div style={bottomChipsStyle} className="card-click-class">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 4,
                    alignItems: "center",
                  }}
                >
                  <DueDateChip
                    firebase={firebase}
                    dueDate={jobData.dueDate}
                    complete={jobData.complete}
                    expressDueDate={jobData.expressDueDate}
                    expressComplete={jobData.expressComplete}
                    id={jobData.id}
                    theme={theme}
                    canEdit={true}
                    cardBackgroundColour={cardBackgroundColour}
                  />
                  <DaysInShootingChip jobData={jobData} />
                  <ChecklistsChip
                    jobData={jobData}
                    cardBackgroundColour={cardBackgroundColour}
                  />
                  <ImagesChip
                    jobData={jobData}
                    clientData={clientData}
                    cardBackgroundColour={cardBackgroundColour}
                  />
                </div>
              </div>
              {jobData.assignedStaff && jobData.assignedStaff.length > 0 ? (
                <AvatarGroup sx={{ marginTop: 0.5 }}>
                  {jobData.assignedStaff &&
                    jobData.assignedStaff.map((as, i) => {
                      const assignedData = returnStaffDataFromID(as, staffData);
                      const name = `${assignedData.firstName} ${assignedData.lastName}`;
                      return (
                        <Tooltip
                          title={name}
                          key={`assignedStaff_${name}_${i}`}
                        >
                          <Avatar
                            sx={{
                              width: 26,
                              height: 26,
                              bgcolor: stringToColor(name),
                            }}
                            alt={name}
                            src={assignedData.avatar}
                          />
                        </Tooltip>
                      );
                    })}
                </AvatarGroup>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const returnStaffDataFromID = (id, staffData) => {
  let res = {};
  staffData.forEach((sd) => {
    if (sd.id === id) res = sd;
  });
  return res;
};

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

const DaysInShootingChip = ({ jobData }) => {
  const [labelColours] = useAtom(labelColoursAtom);
  const [theme] = useAtom(themeAtom);

  const [returnNull, setReturnNull] = useState(false);
  const [daysInShooting, setDaysInShooting] = useState(0);

  useEffect(() => {
    const main = () => {
      if (jobData.isInShooting) {
        setReturnNull(false);

        setDaysInShooting(
          getDaysDifference(
            jobData.isInShootingTime,
            DateTime.now()
              .setZone("Australia/Sydney")
              .startOf("day")
              .toUTC()
              .toISO()
          )
        );
      } else {
        setReturnNull(true);
        setDaysInShooting(0);
        return;
      }
    };
    main();
  }, [jobData]);

  function parseColour(days) {
    if (days === 0) return theme === "dark" ? "#272727" : "#ebebeb";
    if (days === 1) return labelColours["sky"][theme];
    if (days === 2) return labelColours["yellow"][theme];
    if (days >= 3) return labelColours["red"][theme];
  }

  return (
    <>
      {returnNull ? null : (
        <Chip
          label={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
              }}
            >
              Days: {daysInShooting}
            </span>
          }
          sx={{
            marginTop: 0.6,
            backgroundColor: parseColour(daysInShooting),
            padding: 0,
            borderRadius: 0.8,
          }}
          size="small"
        />
      )}
    </>
  );
};

const ChecklistsChip = ({ jobData, cardBackgroundColour }) => {
  const [theme] = useAtom(themeAtom);
  const [labelColours] = useAtom(labelColoursAtom);
  const [returnNull, setReturnNull] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [labelText, setLabelText] = useState("0/0");
  useEffect(() => {
    const main = () => {
      if (!jobData.checklists) {
        setReturnNull(true);
        return;
      } else {
        setReturnNull(false);
      }
      let total = 0;
      let complete = 0;
      jobData.checklists.forEach((cl) => {
        if (cl?.checklistItems?.length > 0) {
          cl.checklistItems.forEach((ci) => {
            total = total + 1;
            if (ci.checked) complete = complete + 1;
          });
        }
      });
      if (total === 0 && complete === 0) {
        setReturnNull(true);
        return;
      }
      if (complete === total) {
        setIsComplete(true);
      } else {
        setIsComplete(false);
      }
      setLabelText(`${complete}/${total}`);
    };
    main();
  }, [jobData]);

  return (
    <>
      {returnNull ? null : (
        <Chip
          icon={<ChecklistIcon />}
          label={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
              }}
            >
              {labelText}
            </span>
          }
          sx={{
            marginTop: 0.6,
            backgroundColor: isComplete
              ? labelColours["lime"][theme]
              : cardBackgroundColour
              ? cardBackgroundColour
              : theme === "dark"
              ? "#272727"
              : "#ebebeb",
            padding: 0,
            borderRadius: 0.8,
          }}
          size="small"
        />
      )}
    </>
  );
};

function getDaysDifference(startDateISOString, endDateISOString) {
  const startDate = DateTime.fromISO(startDateISOString);
  const endDate = DateTime.fromISO(endDateISOString);

  const diff = endDate.diff(startDate, "days").toObject();

  return Math.floor(diff.days);
}

const HeightPreservingItem = ({ children, ...props }) => {
  const [size, setSize] = useState(0);
  const knownSize = props["data-known-size"];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        "--child-height": `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function isJobTypeMatching(jobTypes, noteJobTypes, jobType) {
  let res = false;
  if (!noteJobTypes) {
    return true;
  }
  if (noteJobTypes.length === 0) {
    return true;
  }

  jobTypes.forEach((jt) => {
    const combined = `${jt.name} (-${jt.nameShort})`;
    if (combined === jobType) {
      noteJobTypes.forEach((njt) => {
        if (jt.id === njt) res = true;
      });
    }
  });
  return res;
}
