import React, { useState } from "react";
import {
  Button,
  AppBar,
  Toolbar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

const ShootInfoMenu = ({
  spreadsheetURL,
  jobData,
  clientData,
  isElectron,
  isDev,
  isLife,
  setOpenOperatorChecklistModal,
}) => {
  const buttonStyle = {
    fontSize: "0.8rem",
    borderRadius: 0.4,
    border: "1px solid rgba(150,150,150,0.1)",
    margin: 0.1,
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [folCount, setFolCount] = useState(150);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);

    const args = {
      shouldPrompt: false,
      existingHandler: "useExisting",
      browseHandler: "create",
    };
    const newFols = Array.from({ length: folCount }, (_, index) => {
      return `S${parseShotNumber(index)}`;
    });
    window.ipcRenderer?.send(
      "eval",
      `createFoldersBatch("createFolderWithShotNumber", ${JSON.stringify(
        args
      )}, ${JSON.stringify(newFols)})`
    );

    setFolCount(150);
  };

  function parseShotNumber(length) {
    length = length + 1;
    if (length < 10) {
      length = `0${length}`;
    }
    return length;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleDialogConfirm();
    }
  };

  return (
    <AppBar position="static" color="transparent" id="menubar">
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 15,
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Button
              color="inherit"
              sx={buttonStyle}
              onClick={() => {
                window.open(spreadsheetURL, "_blank");
              }}
            >
              Shot List
            </Button>
            {/* <Button
              color="inherit"
              sx={buttonStyle}
              onClick={() => {
                if (!isElectron) {
                  const newShotListURL = `https://staff.imagesthatsell.com.au/shotlist?clientID=${clientData.id}&jobID=${jobData.id}`;
                  window.open(newShotListURL, "_blank");
                } else {
                  window.ipcRenderer.send(
                    "openSheetWindow",
                    `https://${
                      isDev() ? "localhost:3000" : "staff.imagesthatsell.com.au"
                    }/shotlist?clientID=${clientData.id}&jobID=${jobData.id}`
                  );
                }
              }}
            >
              Shot List Beta
            </Button> */}
            <Button
              color="inherit"
              sx={buttonStyle}
              onClick={() => {
                setOpenOperatorChecklistModal(true);
              }}
            >
              Operator Checklist
            </Button>
            {isElectron ? (
              <Button
                color="inherit"
                sx={buttonStyle}
                onClick={() => {
                  if (!checkShotList(jobData)) {
                    handleDialogOpen(true);
                  } else {
                    if (!isElectron) {
                      const newShotListURL = `https://staff.imagesthatsell.com.au/shotlist?clientID=${clientData.id}&jobID=${jobData.id}`;
                      window.open(newShotListURL, "_blank");
                    } else {
                      window.ipcRenderer.send(
                        "openSheetWindow",
                        `https://${
                          isDev()
                            ? "localhost:3000"
                            : "staff.imagesthatsell.com.au"
                        }/shotlist?clientID=${clientData.id}&jobID=${
                          jobData.id
                        }`
                      );
                    }
                  }
                }}
              >
                Create Folders
              </Button>
            ) : null}
          </Box>
        </div>
      </Toolbar>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>How many folders do you want to make?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            fullWidth
            variant="standard"
            value={folCount}
            onChange={(e) => setFolCount(Number(e.target.value))}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default ShootInfoMenu;

function checkShotList(jobData) {
  if (!jobData) return false;
  if (!jobData.shotList) return false;
  if (!Array.isArray(jobData.shotList)) return false;

  return jobData.shotList.some((item) => {
    return item.hasOwnProperty("fileName") && item.fileName.trim() !== "";
  });
}
