import React from "react";

import {
  Body,
  Button as EmailButton,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Text,
  Preview,
  Section,
  Tailwind,
} from "@react-email/components";
import { Markdown } from "@react-email/markdown";

const AllSupplyEmailContainer = ({
  emailMarkdown,
  linkButtons,
  selectsUrl,
  previewText,
  emailKind,
}) => {
  const markdownCustomStyles = {
    h1: {
      fontWeight: 700,
      fontSize: "28px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "14px",
      marginTop: "2px",
      marginBottom: "2px",
    },
  };
  const regexTop = /{{links}}[\s\S]*/;
  const regexBottom = /[\s\S]*{{links}}/;

  return (<>
    <Html>
      <Head />
      <Preview>{previewText}</Preview>
      <Tailwind>
        <Body
          className="bg-white my-auto mx-auto font-sans"
          style={{ overflow: "scroll" }}
        >
          <Container className="block border border-solid border-[#eaeaea] rounded my-[40px] mx-auto p-[25px] w-[550px]">
            <Section className="mb-[40px] -mt-[20px]">
              <Img
                src={`https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg`}
                width="100"
                alt="ITS LOGO"
                className="my-0 mx-auto"
              />
            </Section>
            {emailKind.includes("Outsourcers") &&
            emailKind.includes("includeRetouchingNotes") ? (
              <>
                <Section className="text-black text-[14px] font-normal mb-[20px] -mt-[20px] min-w-[500px]">
                  <Markdown markdownCustomStyles={markdownCustomStyles}>
                    {emailMarkdown
                      .replace(/^\s*$/gm, "<br>")
                      .replace(
                        /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                        `<span style="color: $2">$3</span>`
                      )
                      .replace(
                        /(\*\*|__)(.*?)(\*?)\1/gm,
                        `<span style="font-weight: bold;">$2</span>`
                      )
                      .replace(
                        /(\*|_)(.*?)(\*?)\1/gm,
                        `<span style="font-style: italic;">$2</span>`
                      )
                      .replace(regexTop, "")}
                  </Markdown>
                </Section>
                <Section className="text-black text-[14px] font-normal text-center mb-[20px] -mt-[20px] min-w-[500px]">
                  <Markdown markdownCustomStyles={markdownCustomStyles}>
                    {linkButtons.replace(/<br>\n<br>$/, "")}
                  </Markdown>
                </Section>
                <Section className="text-black text-[14px] font-normal mb-[20px] -mt-[20px] min-w-[500px]">
                  <Markdown markdownCustomStyles={markdownCustomStyles}>
                    {emailMarkdown
                      .replace(/^\s*$/gm, "<br>")
                      .replace(
                        /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                        `<span style="color: $2">$3</span>`
                      )
                      .replace(
                        /(\*\*|__)(.*?)(\*?)\1/gm,
                        `<span style="font-weight: bold;">$2</span>`
                      )
                      .replace(
                        /(\*|_)(.*?)(\*?)\1/gm,
                        `<span style="font-style: italic;">$2</span>`
                      )
                      .replace(regexBottom, "")}
                  </Markdown>
                </Section>
              </>
            ) : emailKind.includes("Outsourcers") &&
              emailKind.includes("noRetouchingNotes") ? (
              <>
                <Section className="text-black text-[14px] font-normal mb-[20px] -mt-[20px] min-w-[500px]">
                  <Markdown markdownCustomStyles={markdownCustomStyles}>
                    {emailMarkdown
                      .replace(/^\s*$/gm, "<br>")
                      .replace(
                        /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                        `<span style="color: $2">$3</span>`
                      )
                      .replace(
                        /(\*\*|__)(.*?)(\*?)\1/gm,
                        `<span style="font-weight: bold;">$2</span>`
                      )
                      .replace(
                        /(\*|_)(.*?)(\*?)\1/gm,
                        `<span style="font-style: italic;">$2</span>`
                      )
                      .replace(regexTop, "")}
                  </Markdown>
                </Section>
              </>
            ) : emailKind === "Video" ? (
              <>
                <Section className="text-black text-[14px] font-normal mb-[20px] -mt-[20px] min-w-[500px]">
                  <Markdown markdownCustomStyles={markdownCustomStyles}>
                    {emailMarkdown
                      .replace(/^\s*$/gm, "<br>")
                      .replace(
                        /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                        `<span style="color: $2">$3</span>`
                      )
                      .replace(
                        /(\*\*|__)(.*?)(\*?)\1/gm,
                        `<span style="font-weight: bold;">$2</span>`
                      )
                      .replace(
                        /(\*|_)(.*?)(\*?)\1/gm,
                        `<span style="font-style: italic;">$2</span>`
                      )
                      .replace(
                        "{{links}}",
                        linkButtons.replace(/<br>\n<br>$/, "")
                      )}
                  </Markdown>
                </Section>
              </>
            ) : (
              <Section className="text-black text-[14px] font-normal text-center mb-[20px] -mt-[20px] min-w-[500px]">
                <Markdown markdownCustomStyles={markdownCustomStyles}>
                  {emailMarkdown
                    .replace(/^\s*$/gm, "<br>")
                    .replace(
                      /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                      `<span style="color: $2">$3</span>`
                    )
                    .replace(
                      /(\*\*|__)(.*?)(\*?)\1/gm,
                      `<span style="font-weight: bold;">$2</span>`
                    )
                    .replace(
                      /(\*|_)(.*?)(\*?)\1/gm,
                      `<span style="font-style: italic;">$2</span>`
                    )
                    .replace(
                      "{{links}}",
                      linkButtons.replace(/<br>\n<br>$/, "")
                    )}
                </Markdown>
              </Section>
            )}

            {selectsUrl ? (
              <>
                <Heading className="text-black text-[14px] font-normal text-center p-0 mt-[20px] mb-[10px] mx-0">
                  STEPS TO ORDER YOUR FILES
                </Heading>
                <Text className="text-black text-[12px] font-normal p-0 mx-0">
                  <strong>STEP 1:</strong> Review your Storyboards and confirm
                  if you are happy with the images selects on the day.
                </Text>
                <Text className="text-black text-[12px] font-normal -mt-[12px] p-0 mx-0">
                  If you are happy with the selected images, Please send an
                  email to production@imagesthatsell.com.au confirming the
                  Storyboard images.
                </Text>
                <Text className="text-black text-[12px] font-normal my-[8px] p-0 mx-0">
                  If you want to reselect the images, Please continue to Step
                  2
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <strong>STEP 2:</strong> Download the Quickproof (Low Res)
                  files from the above link to a folder on your desktop.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <strong>STEP 3:</strong> Create a selects folder on your
                  desktop - Drag and drop your selects from the downloaded
                  Quickproof folder to your selects folder. Confirm the amount
                  of selects you have ordered is within your daily file
                  allocation.
                </Text>
                <Text className="text-black text-[12px] font-normal -mt-[12px] p-0 mx-0">
                  Please note there is a fee for additional retouched images.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <strong>STEP 4:</strong> Open this link:
                </Text>
                <Section className="text-center mt-[10px] mb-[10px]">
                  <EmailButton
                    pX={20}
                    pY={12}
                    className="bg-[#000000] rounded text-white text-[12px] font-semibold no-underline text-center"
                    href={selectsUrl}
                  >
                    <span style={{ textTransform: "uppercase" }}>
                      Selects
                    </span>
                  </EmailButton>
                </Section>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <strong>STEP 5:</strong> Click the “Add Files” button &
                  navigate to your selected images within your selects folder
                  on your desktop.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <strong>STEP 6:</strong> Click “Open” & wait for your images
                  to upload
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[20px] p-0 mx-0">
                  Once your images have uploaded, Please send us an email
                  letting us know the files have been uploaded & how many
                  there are so the retouching process can begin.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  If your selects are sent through before midday, your due
                  date will be 5 business days from the day we receive your
                  selects, otherwise it will be 6 business days.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  <Link
                    href={`mailto:production@imagesthatsell.com.au`}
                    className="text-black underline"
                  >
                    production@imagesthatsell.com.au
                  </Link>
                  will confirm the receipt of your selects and will provide
                  the due date via email.
                </Text>
                <Text className="text-black text-[12px] font-normal mt-[12px] p-0 mx-0">
                  If you have any questions, please don't hesitate to email
                  <Link
                    href={`mailto:production@imagesthatsell.com.au`}
                    className="text-black underline"
                  >
                    production@imagesthatsell.com.au
                  </Link>
                </Text>
              </>
            ) : null}
            {showThanks(emailKind) ? (
              <Heading className="text-black text-[14px] font-normal text-center p-0 m-[0px] mx-0">
                Thank you for working with ITS
              </Heading>
            ) : null}
          </Container>
        </Body>
      </Tailwind>
    </Html>
  </>);
};

export default AllSupplyEmailContainer;

const showThanks = (emailKind) => {
  if (emailKind.includes("Outsourcers")) return false;
  if (emailKind === "Video") return false;
  if (emailKind === "Product Storyboards") return false;
  return true;
};
