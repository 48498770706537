import React, { useState, useEffect, useRef, useCallback } from "react";

import "../../Trello/emailModal.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";

import { debounce } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "react-quill/dist/quill.snow.css";
import Popover from "@mui/material/Popover";

import { render } from "@react-email/components";

import {
  Body,
  Container,
  Head,
  Html,
  Img,
  Preview,
  Section,
  Tailwind,
} from "@react-email/components";

import { Markdown } from "@react-email/markdown";
import MDEditor, { commands } from "@uiw/react-md-editor";

const FirstConfirmationEmailContainer = ({
  firebase,
  confirmationEmailData,
  setConfirmationEmailData,
  setConfirmationStatusEmailOpen,
  isMobile,
  theme,
}) => {
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([
    { name: "Adam Flipp", email: "adam@imagesthatsell.com.au" },
    { name: "Jodie Rogers", email: "jodie@imagesthatsell.com.au" },
  ]);
  const [bccEmails, setBccEmails] = useState([]);
  const [ccVisible, setCcVisible] = useState(true);
  const [bccVisible, setBccVisible] = useState(false);
  const [subject, setSubject] = useState("Booking Confirmation");
  const attachments = [];
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  useEffect(() => {
    if (confirmationEmailData?.clientData?.contacts) {
      setToEmails(confirmationEmailData.clientData.contacts);
    } else {
      setToEmails([]);
    }
  }, [confirmationEmailData, setToEmails]);

  const emailContainerMainStyle = {
    width: "100vw",
    zIndex: 100,
  };
  const emailContainerStyle = {
    width: "100%",
    margin: 15,
    marginTop: 20,
    position: "relative",
  };
  const toggleCC = () => setCcVisible(!ccVisible);
  const toggleBCC = () => setBccVisible(!bccVisible);
  // eslint-disable-next-line
  const getEmailsFromClientObjectArray = (arr) => {
    let tmpArr = [];
    arr.forEach((c) => {
      if (c.email) {
        tmpArr.push(c.email);
      }
    });
    return tmpArr;
  };

  // eslint-disable-next-line
  const updateFSNow = useCallback(
    debounce((firebase, id, field, value) => {
      firebase

        .collection("JobData")
        .doc(id)
        .update({ [field]: value }, { merge: true });
    }, 1500),
    []
  );

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    // const newDate = new Date(confirmationEmailData.jobData.bookedStart);
    const newDate = new Date(confirmationEmailData.jobData.bookedStart);
    newDate.setHours(1);
    newDate.setMinutes(0);
    // newDate.setDate(newDate.getDate() - 1);
    let unsub = firebase
      .firestore()
      .collection("JobData")
      .where("bookedStart", ">", newDate.toISOString())
      .where("confirmationStatus", "==", "confirmed")
      .where("client", "==", confirmationEmailData.jobData.client)
      .orderBy("bookedStart", "asc")
      .onSnapshot((collection) => {
        const jd = collection.docs.map((job) => {
          const tmp = job.data();
          tmp.id = job.id;
          const data = {
            data: tmp,
            label: `${parseLabelDate(
              tmp.bookedStart
            )} - ${tmp.jobTypeFull.replace(/ (.*)/, "")} - ${tmp.jobName}`,
          };
          return data;
        });
        setJobs([...jd]);
      });
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase]);

  const parseLabelDate = (d) => {
    const date = new Date(d);
    const mon = date.getMonth();
    let month;
    if (mon === 0) month = "JAN";
    if (mon === 1) month = "FEB";
    if (mon === 2) month = "MAR";
    if (mon === 3) month = "APR";
    if (mon === 4) month = "MAY";
    if (mon === 5) month = "JUN";
    if (mon === 6) month = "JUL";
    if (mon === 7) month = "AUG";
    if (mon === 8) month = "SEP";
    if (mon === 9) month = "OCT";
    if (mon === 10) month = "NOV";
    if (mon === 11) month = "DEC";
    let day = date.getDate();
    function parseDay(i) {
      var j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "ST";
      }
      if (j === 2 && k !== 12) {
        return i + "ND";
      }
      if (j === 3 && k !== 13) {
        return i + "RD";
      }
      return i + "TH";
    }
    return `${parseDay(day)} ${month}`;
  };
  const [selectedJobs, setSelectedJobs] = useState([]);

  const getJob = (job) => {
    for (let i = 0; i < jobs.length; i++) {
      if (jobs[i].label === job) return jobs[i];
    }
  };

  const getJobTypes = () => {
    let tmp = [];
    tmp.push(confirmationEmailData.jobData.jobTypeShort);
    selectedJobs.forEach((job) => {
      tmp.push(getJob(job).data.jobTypeShort);
    });
    tmp = [...new Set(tmp)];
    return tmp;
  };

  const [mainJob] = useState(
    `**<colour #ED6C4B>${parseDate(
      confirmationEmailData.jobData
    )}</colour>**<br>`
  );
  const [additionalJobs, setAdditionalJobs] = useState("");

  useEffect(() => {
    let tmp = "";
    // console.log(selectedJobs);

    selectedJobs.forEach((job) => {
      // setJobTypes([...jobTypes, getJob(job).data.jobTypeShort]);

      tmp = `${tmp}**<colour #ED6C4B>${parseDate(
        getJob(job).data
      )}</colour>**<br>
`;
    });
    setAdditionalJobs(tmp);
    setEmailMarkdown(`<p style="line-height: 23px">
    ${mainJob}
    ${tmp}
</p>`);
    // eslint-disable-next-line
  }, [selectedJobs]);

  const [emailMarkdown, setEmailMarkdown] =
    useState(`<p style="line-height: 23px">
${mainJob}
${additionalJobs}
</p>`);

  const [markdownEditorOpen, setMarkdownEditorOpen] = useState(null);

  const handleMarkdownEditorClick = (event) => {
    if (!markdownEditorOpen) {
      setMarkdownEditorOpen(event.currentTarget);
    } else {
      handleMarkdownEditorClose();
    }
  };

  const handleMarkdownEditorClose = () => {
    setMarkdownEditorOpen(null);
  };

  const [topText, setTopText] = useState(`Hi


  I hope you've had a great start to the week! 
  

  I'm just getting in touch to confirm your upcoming booking.
  

  Please review and confirm the below. 
  

  Best

  Amy x`);
  const [topTextAnchorEl, setTopTextAnchorEl] = useState(null);
  const handleTopTextMenuOpen = (event) => {
    setTopTextAnchorEl(event.currentTarget);
  };
  const handleTopTextMenuClose = () => {
    setTopTextAnchorEl(null);
  };

  return (<>
    <Menu
      anchorEl={topTextAnchorEl}
      open={Boolean(topTextAnchorEl)}
      onClose={handleTopTextMenuClose}
    >
      <div style={{ width: 320, display: "flex", justifyContent: "center" }}>
        <TextField
          sx={{ width: 280 }}
          value={topText}
          onChange={(e) => {
            setTopText(e.target.value);
          }}
          multiline
          minRows={6}
        />
      </div>
    </Menu>
    <LoadingFullScreen loadingVisible={loadingVisible} theme={theme} />
    <SuccessFullScreen successVisible={successVisible} theme={theme} />
    <ErrorFullScreen
      errorVisible={errorVisible}
      errorMessage={errorMessage}
    />
    {markdownEditorOpen ? (
      <Popover
        open={Boolean(markdownEditorOpen)}
        anchorEl={markdownEditorOpen}
        onClose={handleMarkdownEditorClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ borderRadius: "0px !important", marginLeft: 1 }}
      >
        <div data-color-mode="light" style={{ maxWidth: "20vw" }}>
          <MDEditor
            preview="edit"
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.group(
                [
                  commands.title1,
                  commands.title2,
                  commands.title3,
                  commands.title4,
                  commands.title5,
                  commands.title6,
                ],
                {
                  name: "title",
                  groupName: "title",
                  buttonProps: { "aria-label": "Insert title" },
                }
              ),
              commands.group([], {
                name: "Colour",
                groupName: "Colour",
                icon: (
                  <svg width="12px" height="12px" viewBox="0 0 16 16">
                    <g id="surface1">
                      <path
                        fill="currentColor"
                        style={{
                          transform:
                            "scale(1.4) translateX(-2px) translateY(-2px)",
                        }}
                        d="M 3.332031 12 L 12.667969 12 L 12.667969 14 L 3.332031 14 Z M 8.332031 2.667969 L 7.667969 2.667969 C 7.398438 2.667969 7.15625 2.828125 7.050781 3.074219 L 3.828125 10.667969 L 5.277344 10.667969 L 6.128906 8.667969 L 9.863281 8.667969 L 10.707031 10.667969 L 12.15625 10.667969 L 8.949219 3.074219 C 8.84375 2.828125 8.601562 2.667969 8.332031 2.667969 Z M 6.691406 7.332031 L 8 4.257812 L 9.300781 7.332031 Z M 6.691406 7.332031 "
                      />
                    </g>
                  </svg>
                ),
                children: ({ close, execute, getState, textApi }) => {
                  const setColour = (hex) => {
                    const state = getState();
                    const api = textApi;
                    const startPos = state.selection.start;
                    // const endPos = state.selection.end;
                    const text = state.text;
                    const replacementText = `<colour ${hex}>${state.selectedText}</colour>`;

                    const startSub = text.substring(0, startPos);
                    // const endSub = text.substring(endPos);
                    // const newText = `${startSub}${replacementText}${endSub}`;

                    api.replaceSelection(replacementText);
                    api.setSelectionRange({
                      start:
                        startSub.length +
                        replacementText.indexOf(state.selectedText),
                      end:
                        startSub.length +
                        replacementText.indexOf(state.selectedText) +
                        state.selectedText.length,
                    });
                    close();
                  };

                  const resetText = () => {
                    const state = getState();
                    const api = textApi;

                    const replacementText = state.text.replace(
                      /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                      state.selectedText
                    );
                    api.setSelectionRange({
                      start: 0,
                      end: textApi.textArea.value.length,
                    });
                    api.replaceSelection(replacementText);
                    close();
                  };

                  const buttonStyle = {
                    width: 16,
                    height: 16,
                    cursor: "pointer",
                    margin: 0.5,
                  };

                  const greyArray = [
                    "#FFFFFF",
                    "#E8E8E8",
                    "#D1D1D1",
                    "#BABABA",
                    "#A2A2A2",
                    "#8B8B8B",
                    "#747474",
                    "#5D5D5D",
                    "#454545",
                    "#2F2F2F",
                    "#171717",
                    "#000000",
                  ];

                  const colourArrays = {
                    redArray: [
                      "#300402",
                      "#600F07",
                      "#911D0E",
                      "#C12917",
                      "#F23620",
                      "#F14A3D",
                      "#F4716A",
                      "#F69E9C",
                      "#FACECD",
                    ],
                    orangeArray: [
                      "#311B05",
                      "#63350E",
                      "#935019",
                      "#C46C25",
                      "#F4852F",
                      "#F79D49",
                      "#F9B571",
                      "#FACD9F",
                      "#FDE5CF",
                    ],
                    yellowArray: [
                      "#333209",
                      "#67651B",
                      "#9A972D",
                      "#CDCA40",
                      "#FFFC52",
                      "#FFFD5F",
                      "#FFFD7E",
                      "#FFFDA4",
                      "#FFFED2",
                    ],
                    limeArray: [
                      "#1F3208",
                      "#3D6319",
                      "#5C952A",
                      "#7AC63B",
                      "#99F94C",
                      "#ACFA5B",
                      "#BFFA7B",
                      "#D5FCA4",
                      "#E9FDD0",
                    ],
                    greenArray: [
                      "#0E3108",
                      "#246319",
                      "#3A9529",
                      "#50C63A",
                      "#65F84B",
                      "#6FF85A",
                      "#88F97A",
                      "#ABFAA3",
                      "#D4FCD1",
                    ],
                    forrestArray: [
                      "#0D321C",
                      "#236338",
                      "#399554",
                      "#4EC771",
                      "#64F98C",
                      "#6DF9A1",
                      "#87FAB9",
                      "#AAFBCF",
                      "#D4FDE7",
                    ],
                    aquaArray: [
                      "#0C3232",
                      "#216465",
                      "#369698",
                      "#4AC9CB",
                      "#5FFBFD",
                      "#69FBFE",
                      "#84FBFE",
                      "#A9FCFE",
                      "#D3FDFE",
                    ],
                    skyArray: [
                      "#021931",
                      "#073462",
                      "#0D4E94",
                      "#1468C6",
                      "#1B83F7",
                      "#409AF8",
                      "#70B3F9",
                      "#9ECBFB",
                      "#CEE5FD",
                    ],
                    blueArray: [
                      "#000230",
                      "#000A61",
                      "#001493",
                      "#001FC3",
                      "#002AF5",
                      "#2342F6",
                      "#606DF7",
                      "#969CF9",
                      "#CBCDFB",
                    ],
                    purpleArray: [
                      "#170430",
                      "#2E0E62",
                      "#441993",
                      "#5C25C4",
                      "#7331F6",
                      "#8E48F6",
                      "#A871F6",
                      "#C59FF9",
                      "#E1CEFC",
                    ],
                    magentaArray: [
                      "#2F0731",
                      "#5F1663",
                      "#902694",
                      "#BF36C5",
                      "#F045F7",
                      "#EF55F7",
                      "#F277F8",
                      "#F5A2FA",
                      "#F9CFFC",
                    ],
                    pinkArray: [
                      "#300518",
                      "#601132",
                      "#911F4C",
                      "#C12C65",
                      "#F13A7F",
                      "#F14D97",
                      "#F373B0",
                      "#F6A0CA",
                      "#FACFE4",
                    ],
                  };

                  const newShade = (hexColor, magnitude) => {
                    hexColor = hexColor.replace(`#`, ``);
                    if (hexColor.length === 6) {
                      const decimalColor = parseInt(hexColor, 16);
                      let r = (decimalColor >> 16) + magnitude;
                      r > 255 && (r = 255);
                      r < 0 && (r = 0);
                      let g = (decimalColor & 0x0000ff) + magnitude;
                      g > 255 && (g = 255);
                      g < 0 && (g = 0);
                      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
                      b > 255 && (b = 255);
                      b < 0 && (b = 0);
                      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
                    } else {
                      return hexColor;
                    }
                  };

                  function hexToRgb(hex) {
                    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                      return r + r + g + g + b + b;
                    });

                    var result =
                      /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                    return result
                      ? {
                          r: parseInt(result[1], 16),
                          g: parseInt(result[2], 16),
                          b: parseInt(result[3], 16),
                        }
                      : null;
                  }

                  function luminance(r, g, b) {
                    var a = [r, g, b].map(function (v) {
                      v /= 255;
                      return v <= 0.03928
                        ? v / 12.92
                        : Math.pow((v + 0.055) / 1.055, 2.4);
                    });
                    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
                  }

                  function calculateRatio(color1, color2) {
                    const color1rgb = hexToRgb(color1);
                    const color2rgb = hexToRgb(color2);

                    const color1luminance = luminance(
                      color1rgb.r,
                      color1rgb.g,
                      color1rgb.b
                    );
                    const color2luminance = luminance(
                      color2rgb.r,
                      color2rgb.g,
                      color2rgb.b
                    );

                    const ratio =
                      color1luminance > color2luminance
                        ? (color2luminance + 0.05) / (color1luminance + 0.05)
                        : (color1luminance + 0.05) / (color2luminance + 0.05);

                    return ratio;
                  }

                  return (
                    <div
                      style={{
                        padding: 10,
                      }}
                    >
                      <div style={{ fontSize: 12, marginBottom: 10 }}>
                        Text Colour
                      </div>
                      <div style={{ display: "flex", marginBottom: 8 }}>
                        {greyArray &&
                          greyArray.map((hex) => (
                            <div
                              type="button"
                              style={{
                                ...buttonStyle,
                                backgroundColor: hex,
                                border:
                                  calculateRatio(hex, "#ffffff") < 1 / 4.5
                                    ? null
                                    : `1px solid ${newShade(hex, -40)}`,
                              }}
                              onClick={() => setColour(hex)}
                            ></div>
                          ))}
                      </div>

                      <div style={{ display: "flex" }}>
                        {colourArrays &&
                          Object.keys(colourArrays).map((colour) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {colourArrays[colour].map((hex) => (
                                <div
                                  type="button"
                                  style={{
                                    ...buttonStyle,
                                    backgroundColor: hex,
                                    border:
                                      calculateRatio(hex, "#ffffff") < 1 / 4.5
                                        ? null
                                        : `1px solid ${newShade(hex, -40)}`,
                                  }}
                                  onClick={() => setColour(hex)}
                                ></div>
                              ))}
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <button onClick={resetText}>Reset to default</button>
                      </div>
                    </div>
                  );
                },
                execute: (state, api) => {
                  api.textArea.selectionStart = state.selection.start;
                  api.textArea.selectionEnd = state.selection.end;
                },
                buttonProps: { "aria-label": "Insert Colour" },
              }),
              commands.link,
            ]}
            extraCommands={[]}
            height="60vh"
            value={emailMarkdown}
            onChange={setEmailMarkdown}
          />
        </div>
      </Popover>
    ) : null}
    <div
      style={{
        background: theme === "dark" ? "#1f1f1f " : "#ffffff",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div style={emailContainerMainStyle}>
        <div style={emailContainerStyle}>
          <EmailField
            emails={toEmails}
            setEmails={setToEmails}
            kind={"To"}
            visible={true}
            marginRight={20}
            isMobile={isMobile}
            firebase={firebase}
            confirmationEmailData={confirmationEmailData}
            jobs={jobs}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            ccVisible={ccVisible}
            bccVisible={bccVisible}
          />
          <Divider />
          <EmailField
            emails={ccEmails}
            setEmails={setCcEmails}
            kind={"Cc"}
            visible={ccVisible}
            marginRight={0}
            isMobile={isMobile}
            firebase={firebase}
            confirmationEmailData={confirmationEmailData}
            jobs={jobs}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            ccVisible={ccVisible}
            bccVisible={bccVisible}
          />
          <Divider style={{ display: ccVisible ? "flex" : "none" }} />
          <EmailField
            emails={bccEmails}
            setEmails={setBccEmails}
            kind={"Bcc"}
            visible={bccVisible}
            marginRight={0}
            isMobile={isMobile}
            firebase={firebase}
            confirmationEmailData={confirmationEmailData}
            jobs={jobs}
            selectedJobs={selectedJobs}
            setSelectedJobs={setSelectedJobs}
            ccVisible={ccVisible}
            bccVisible={bccVisible}
          />
          <Divider style={{ display: bccVisible ? "flex" : "none" }} />
          <div
            style={{
              width: isMobile ? "90vw" : 750,
              marginTop: 5,
              marginBottom: 5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  marginRight: 10,
                  fontSize: "0.8em",
                }}
              >
                Subject:
              </p>
              <TextField
                size="small"
                variant="standard"
                inputProps={{
                  sx: {
                    marginTop: "5px",
                    height: 20,
                    width: 245,
                    fontSize: "14px",
                  },
                }}
                InputProps={{ disableUnderline: true }}
                value={subject || ""}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>

            <ButtonGroup size="small" style={{ height: 24, marginRight: 25 }}>
              <Button
                variant={ccVisible ? "contained" : "outlined"}
                onClick={toggleCC}
              >
                CC
              </Button>
              <Button
                variant={bccVisible ? "contained" : "outlined"}
                onClick={toggleBCC}
              >
                BCC
              </Button>
            </ButtonGroup>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              width: 725,
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ marginTop: 1 }}
              onClick={handleTopTextMenuOpen}
            >
              Top Text
            </Button>
          </div>
        </div>
      </div>
      <Fab
        color="primary"
        style={{
          width: "50px",
          height: "50px",
          zIndex: 200,
          position: "absolute",
          bottom: 14,
          right: 14,
        }}
        onClick={() => {
          setLoadingVisible(true);
          const emailHtml = render(
            <ConfirmedEmailBody
              emailMarkdown={emailMarkdown}
              handleMarkdownEditorClick={handleMarkdownEditorClick}
              confirmationEmailData={confirmationEmailData}
              additionalJobs={additionalJobs}
              topText={topText}
              getJobTypes={getJobTypes}
            />
          );
          // console.log(emailHtml)
          sendEmail({
            emailBody: emailHtml,
            fromEmail: "bookings@imagesthatsell.com.au",
            toEmails: getEmailsFromClientObjectArray(toEmails),
            ccEmails: getEmailsFromClientObjectArray(ccEmails),
            bccEmails: getEmailsFromClientObjectArray(bccEmails),
            replyToEmails: [],
            subject,
            attachments,
          }).then((res) => {
            console.log(res);
            if (res.data.error) {
              setErrorMessage(res.data.errortext);
              setErrorVisible(true);
            } else {
              setSuccessVisible(true);

              setTimeout(() => {
                setConfirmationStatusEmailOpen(false);
              }, 4000);
            }
            setLoadingVisible(false);
          });
        }}
      >
        <SendIcon sx={{ marginLeft: "4px", width: "30px", height: "30px" }} />
      </Fab>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          marginTop: 0,
          position: "relative",
        }}
      >
        <ConfirmedEmailBody
          emailMarkdown={emailMarkdown}
          handleMarkdownEditorClick={handleMarkdownEditorClick}
          confirmationEmailData={confirmationEmailData}
          additionalJobs={additionalJobs}
          topText={topText}
          getJobTypes={getJobTypes}
        />
      </div>
    </div>
  </>);
};

export default FirstConfirmationEmailContainer;

const ConfirmedEmailBody = ({
  emailMarkdown,
  handleMarkdownEditorClick,
  confirmationEmailData,
  additionalJobs,
  topText,
  getJobTypes,
}) => {
  if (!emailMarkdown) emailMarkdown = ``;
  const markdownCustomStyles = {
    h1: {
      fontWeight: 700,
      fontSize: "28px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "14px",
      marginTop: "2px",
      marginBottom: "2px",
    },
  };

  return (
    <Html onClick={handleMarkdownEditorClick}>
      <Head />
      <Preview>Booking Confirmation</Preview>
      <Tailwind>
        <Body className="bg-white my-auto mx-auto font-sans">
          <Markdown className="m-[10px] text-black text-[14px]">
            {`${parseMarkdown(topText)}`}
          </Markdown>
          <Container className="border border-solid border-[#eaeaea] rounded my-[40px] mx-auto p-[40px] w-[620px]">
            <Section className="mb-[40px] -mt-[20px]">
              <Img
                src={`https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg`}
                width="100"
                alt="ITS LOGO"
                className="my-0 mx-auto"
              />
            </Section>
            <Section className="text-black text-[14px] font-normal text-center mb-[20px] -mt-[20px]">
              <Markdown markdownCustomStyles={markdownCustomStyles}>
                {parseMarkdown(`### BOOKING CONFIRMATION
                
PLEASE CHECK THE FOLLOWING DETAILS ARE CORRECT AND CONFIRM YOUR BOOKING:
`)}
              </Markdown>
              <Markdown markdownCustomStyles={markdownCustomStyles}>
                {parseMarkdown(emailMarkdown)}
              </Markdown>
              <Markdown markdownCustomStyles={markdownCustomStyles}>
                {parseMarkdown(`So we can ensure we have sufficient time allocated for your shoot, please confirm shot counts inclusive of all angles required in the below formats.

We can then review and come back for any required amendments to the above booking.`)}
              </Markdown>
              <Markdown markdownCustomStyles={markdownCustomStyles}>
                {parseMarkdown(`
                ${
                  getJobTypes().includes("-P")
                    ? `**<colour #3B4C9D>PRODUCT (EXAMPLE FORMAT ONLY)</colour>**

<colour #EA4025>20</colour> <colour #3B4C9D>x GHOST MANNEQUIN APPAREL - </colour><colour #EA4025>FRONT ONLY</colour>
                    
<colour #EA4025>5</colour> <colour #3B4C9D>x BAGS @</colour> <colour #EA4025>3</colour> <colour #3B4C9D>ANGLES EACH</colour>
                    
<colour #EA4025>5</colour> <colour #3B4C9D>x SHOES @</colour> <colour #EA4025>3</colour> <colour #3B4C9D>ANGLES EACH</colour>
                    
<colour #3B4C9D>TOTAL =</colour> <colour #EA4025>50</colour> <colour #3B4C9D>SHOTS</colour>
                    
                    
<colour #EA4025>*Bookings are based on shot counts supplied at time of booking</colour>
                    
<colour #EA4025>*Additional shots may require additional time</colour>
                    
<colour #EA4025>*Minimum booking = 10 SKUs</colour>
                    `
                    : ""
                }
                

${
  getJobTypes().includes("-L") || getJobTypes().includes("-V")
    ? `**<colour #3B4C9D>LIFE (EXAMPLE FORMAT ONLY)</colour>**

<colour #EA4025>${parseLooks(
        confirmationEmailData.jobData,
        confirmationEmailData.clientData
      )}</colour> x LOOKS @ <colour #EA4025>${parseAngles(
        confirmationEmailData.jobData,
        confirmationEmailData.clientData
      )}</colour> ANGLES = <colour #EA4025>${parseImages(
        confirmationEmailData.jobData,
        confirmationEmailData.clientData,
        "dayType"
      )}</colour> FILES


<colour #EA4025>*ITS package deals: 4 x hours = ${
        confirmationEmailData.clientData.halfDayRate.images
          ? confirmationEmailData.clientData.halfDayRate.images
          : "120"
      } files OR 8 x hours = ${
        confirmationEmailData.clientData.fullDayRate.images
          ? confirmationEmailData.clientData.fullDayRate.images
          : "240"
      } files</colour>

<colour #EA4025>*Inclusive of 1 x hour setup/HMU.</colour>

<colour #EA4025>*Please advise if overtime is required</colour>`
    : ""
}

${
  getJobTypes().includes("-LC") || getJobTypes().includes("-PC")
    ? `**<colour #3B4C9D>CREATIVE (EXAMPLE FORMAT ONLY)</colour>**

<colour #EA4025>${parseImages(
        confirmationEmailData.jobData,
        confirmationEmailData.clientData,
        "creativeDayType"
      )}</colour> FILES


<colour #EA4025>*Inclusive of 1 x hour setup/HMU.</colour>

<colour #EA4025>*Please advise if overtime is required</colour>`
    : ""
}

`)}
              </Markdown>
              <Markdown markdownCustomStyles={markdownCustomStyles}>
                {parseMarkdown(`
<br>
<a href="http://www.imagesthatsell.com.au/docs/its-terms-and-conditions.pdf" data-id="react-email-button" target="_blank" style="line-height: 100%; text-decoration: none; display: inline-block; max-width: 100%; padding: 12px 20px; background-color: rgb(0, 0, 0); border-radius: 0.25rem; color: rgb(255, 255, 255); font-size: 12px; font-weight: 600; text-align: center;">
<span style="max-width: 100%; display: inline-block; line-height: 120%;">
<span style="text-transform: uppercase;">TERMS & CONDITIONS</span>
</span>
</a>`)}
              </Markdown>
            </Section>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

const parseImages = (jobData, clientData, kind) => {
  if (kind === "dayType") {
    if (jobData[kind] === "Full Day" || jobData[kind] === "") {
      if (clientData.fullDayRate?.images) return clientData.fullDayRate.images;
      return 240;
    } else if (jobData[kind] === "Half Day") {
      if (clientData.halfDayRate?.images) return clientData.halfDayRate.images;
      return 120;
    }
  } else if (kind === "creativeDayType") {
    if (jobData[kind] === "Full Day" || jobData[kind] === "") {
      if (clientData.contentFullDayRate?.images)
        return clientData.contentFullDayRate.images;
      return 240;
    } else if (jobData[kind] === "Half Day") {
      if (clientData.contentHalfDayRate?.images)
        return clientData.contentHalfDayRate.images;
      return 120;
    }
  }
  return 240;
};

const parseAngles = (jobData, clientData) => {
  if (jobData.dayType === "Full Day" || jobData.dayType === "") {
    if (clientData.fullDayRate.angles) {
      return clientData.fullDayRate.angles;
    } else if (clientData.fullDayRate.images) {
      if (clientData.fullDayRate.looks) {
        return clientData.fullDayRate.images / clientData.fullDayRate.looks;
      } else {
        return clientData.fullDayRate.images / 60;
      }
    } else if (clientData.fullDayRate.looks) {
      return 240 / clientData.fullDayRate.looks;
    }
  } else if (jobData.dayType === "Half Day") {
    if (clientData.halfDayRate.angles) {
      return clientData.halfDayRate.angles;
    } else if (clientData.halfDayRate.images) {
      if (clientData.halfDayRate.looks) {
        return clientData.halfDayRate.images / clientData.halfDayRate.looks;
      } else {
        return clientData.halfDayRate.images / 30;
      }
    } else if (clientData.halfDayRate.looks) {
      return 120 / clientData.halfDayRate.looks;
    }
  }
  return 4;
};

const parseLooks = (jobData, clientData) => {
  if (jobData.dayType === "Full Day" || jobData.dayType === "") {
    if (clientData.fullDayRate.looks) {
      return clientData.fullDayRate.looks;
    } else return "60";
  } else if (jobData.dayType === "Half Day") {
    if (clientData.halfDayRate.looks) {
      return clientData.halfDayRate.looks;
    } else return "30";
  }
  return "60";
};

const parseMarkdown = (markdown) => {
  markdown = markdown
    .replace(/^\s*$/gm, "<br>")
    .replace(
      /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
      `<span style="color: $2;">$3</span>`
    )
    // .replace(
    //   /(<AdditionalJobs>)(.*?(\r\n?|\n*)?)(<\/AdditionalJobs>)/gm,
    //   additionalJobs
    //   // `<AdditionalJobs>${additionalJobs}</AdditionalJobs>`
    // )
    .replace(
      /(\*\*|__)(.*?)(\*?)\1/gm,
      `<span style="font-weight: bold;">$2</span>`
    )
    .replace(
      /(\*)(.*?)(\*?)\1/gm,
      `<span style="font-style: italic;">$2</span>`
    );
  return markdown;
};

const LoadingFullScreen = ({ loadingVisible, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: loadingVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CircularProgress style={loading.progress} />
      </Box>
    </div>
  );
};
const SuccessFullScreen = ({ successVisible, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: successVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CheckCircleOutlineIcon color="success" style={loading.progress} />

        <span style={{ marginTop: 50 }}>Email Sent!</span>
      </Box>
    </div>
  );
};
const ErrorFullScreen = ({ errorVisible, errorMessage }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: errorVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <ErrorOutlineIcon color="error" style={loading.progress} />

        <span style={{ marginTop: 40 }}>{errorMessage}</span>
        {/* <Button
          onClick={() => window.location.reload()}
          style={{ marginTop: 30 }}
          variant="outlined"
        >
          Retry
        </Button> */}
      </Box>
    </div>
  );
};
const EmailField = ({
  emails,
  setEmails,
  kind,
  visible,
  marginRight,
  isMobile,
  confirmationEmailData,
  jobs,
  selectedJobs,
  setSelectedJobs,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailVisible, setNewEmailVisible] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailButtonVisible, setNewEmailButtonVisible] = useState(true);

  const newEmailRef = useRef(newEmail);
  newEmailRef.current = newEmail;

  const handleDeleteEmailChip = (index) => {
    const tmpClients = [...emails];
    tmpClients.splice(index, 1);
    setEmails([...tmpClients]);
  };
  const handleNewEmailClick = () => {
    setNewEmailButtonVisible(false);
    setNewEmailVisible(true);
    setTimeout(() => {
      if (!newEmailRef.current) {
        setNewEmailButtonVisible(true);
        setNewEmailVisible(false);
        setNewEmailError(false);
      }
    }, 10000);
  };

  const confirmNewEmail = () => {
    if (!newEmailError) {
      setEmails([...emails, { email: newEmail }]);
      setNewEmailVisible(false);
      setNewEmailButtonVisible(true);
      setNewEmail("");
    }
  };

  const emailsContainerStyle = {
    width: isMobile ? "90vw" : 750,
    justifyContent: "space-between",
    minHeight: 10,
    display: visible ? "flex" : "none",
    marginBottom: 2,
    marginTop: 2,
    marginRight: marginRight,
    alignItems: "center",
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedJobs(typeof value === "string" ? value.split(",") : value);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const MenuProps = {
    anchorEl: anchorEl,
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  };
  return (
    (<div style={emailsContainerStyle}>
      <div
        style={{
          display: "flex",
        }}
      >
        <p
          style={{
            marginTop: 8,
            marginBottom: 8,
            marginRight: 10,
            fontSize: "0.8em",
          }}
        >
          {kind}:
        </p>
        <div style={{ marginTop: 0 }}>
          <Stack direction="row" spacing={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {emails &&
                emails.map((toEmail, i) => {
                  if (toEmail.email) {
                    return (
                      <Tooltip
                        key={`EmailChipToolTip-${i}`}
                        title={toEmail.email}
                      >
                        <Chip
                          style={{
                            margin: 2,
                            fontSize: "0.6em",
                          }}
                          size="small"
                          key={`EmailChip-${i}`}
                          label={toEmail.name || toEmail.email}
                          onDelete={() => {
                            handleDeleteEmailChip(i);
                          }}
                        />
                      </Tooltip>
                    );
                  } else return null;
                })}
              <TextField
                style={{
                  marginLeft: 4,
                  marginTop: 3,
                  marginBottom: 2,
                  display: newEmailVisible ? "block" : "none",
                }}
                size="small"
                inputRef={(input) => {
                  if (input) {
                    newEmailVisible && input.focus();
                  }
                }}
                inputProps={{
                  sx: { height: 10, width: 190, fontSize: "11px" },
                }}
                error={newEmailError}
                value={newEmail || ""}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                  // eslint-disable-next-line
                  if (
                    e.target.value.match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                  ) {
                    setNewEmailError(false);
                  } else setNewEmailError(true);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    confirmNewEmail();
                  }
                }}
              />
              <IconButton
                sx={{ width: 26, height: 26 }}
                style={{ display: newEmailButtonVisible ? "flex" : "none" }}
                onClick={handleNewEmailClick}
              >
                <AddCircleIcon color="primary" sx={{ width: 20 }} />
              </IconButton>
            </div>
          </Stack>
        </div>
      </div>
      <div
        style={{ display: kind === "Cc" || kind === "Bcc" ? "none" : "block" }}
      >
        <Button
          variant="outlined"
          size="small"
          style={{ marginTop: -10, marginRight: 25 }}
          onClick={(e) => {
            setOpen(true);
            setAnchorEl(e.target);
          }}
        >
          Select Dates
        </Button>

        <FormControl
          sx={{
            width: 200,
            display: "none",
          }}
        >
          <InputLabel id="Additional-Dates-label">Additional Dates</InputLabel>
          <Select
            size="small"
            labelId="Additional-Dates-label"
            open={open}
            onClose={handleClose}
            multiple
            value={selectedJobs}
            onChange={handleChange}
            input={<OutlinedInput label="Additional Dates" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {jobs.map((job) => (
              <MenuItem
                key={job.label}
                value={job.label}
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                <Checkbox checked={selectedJobs.indexOf(job.label) > -1} />
                <ListItemText
                  primary={job.label}
                  sx={{ marginLeft: 2 }}
                  primaryTypographyProps={{ fontSize: 16 }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>)
  );
};

function parseDate(jobData) {
  let studioName;
  if (jobData.studio === "Studio 1") studioName = "Studio 401";
  if (jobData.studio === "Studio 2A") studioName = "Studio 403";
  if (jobData.studio === "Studio 2B") studioName = "Studio 403";
  if (jobData.studio === "Studio 2C") studioName = "Studio 403";
  if (jobData.studio === "Studio 3") studioName = "Studio 302";
  if (jobData.studio === "Studio 4") studioName = "Studio 301";
  try {
    const date = new Date(jobData.bookedStart);
    const weekDay = parseWeekDay(date.getDay());
    const day = parseDay(date.getDate());
    const month = parseMonth(date.getMonth());
    const jobType = jobData.jobTypeFull.replace(/ \(.*\)/, "").toUpperCase();
    const dayType = jobData.dayType.toUpperCase();

    const lifeRes = `${weekDay} ${day} ${month} ${jobType} ${
      jobData.multipleDays
    } ${jobData.comments ? ` ${jobData.comments}` : ""} ${dayType} ${formatAMPM(
      date
    )} - ${formatAMPM(new Date(jobData.bookedFinish))} - ${studioName}`;
    const ghostRes = `${weekDay} ${day} ${month} ${jobType}${
      jobData.multipleDays ? ` ${jobData.multipleDays}` : ""
    }${jobData.comments ? ` ${jobData.comments}` : ""} x${
      jobData.imagesScheduledToBeShot
    }`;
    let res;
    if (jobData.jobTypeFull === "Creative Life (-LC)") res = lifeRes;
    else if (jobData.jobTypeFull === "Life (-L)") res = lifeRes;
    else if (jobData.jobTypeFull === "Video (-V)") res = lifeRes;
    else res = ghostRes;

    return res;

    function formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + ampm;
      return strTime;
    }

    function parseMonth(month) {
      if (month === 0) return "JANUARY";
      if (month === 1) return "FEBRUARY";
      if (month === 2) return "MARCH";
      if (month === 3) return "APRIL";
      if (month === 4) return "MAY";
      if (month === 5) return "JUNE";
      if (month === 6) return "JULY";
      if (month === 7) return "AUGUST";
      if (month === 8) return "SEPTEMBER";
      if (month === 9) return "OCTOBER";
      if (month === 10) return "NOVEMBER";
      if (month === 11) return "DECEMBER";
    }
    function parseDay(i) {
      var j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "ST";
      }
      if (j === 2 && k !== 12) {
        return i + "ND";
      }
      if (j === 3 && k !== 13) {
        return i + "RD";
      }
      return i + "TH";
    }
    function parseWeekDay(day) {
      if (day === 0) return "SUNDAY";
      if (day === 1) return "MONDAY";
      if (day === 2) return "TUESDAY";
      if (day === 3) return "WEDNESDAY";
      if (day === 4) return "THURSDAY";
      if (day === 5) return "FRIDAY";
      if (day === 6) return "SATURDAY";
    }
  } catch (e) {
    console.log(e);
    console.log(jobData);
  }
}

// function getGreeting() {
//   var d = new Date(),
//     e = new Date(d);
//   var msSinceMidnight = e - d.setHours(0, 0, 0, 0);
//   var sSinceMidnight = msSinceMidnight / 1000;
//   if (sSinceMidnight < 42600) {
//     return "Good Morning";
//   } else if (sSinceMidnight > 42600 && sSinceMidnight < 43800) {
//     return "Hi";
//   } else if (sSinceMidnight > 43800) {
//     return "Good Afternoon";
//   }
// }
