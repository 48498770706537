import React, { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Divider, Dialog } from "@mui/material";
import { FormControlLabel, ToggleButton, Switch } from "@mui/material";
import { ToggleButtonGroup, DialogTitle } from "@mui/material";
import { DialogContent, DialogContentText } from "@mui/material";
import { Avatar, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import ProgressCircle from "./ProgressCircle";

import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ParkIcon from "@mui/icons-material/Park";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useAtom } from "jotai";
import {
  currentStaffDataAtom,
  isMobileBrowserAtom,
  isElectronAtom,
//   isAdminAtom,
  showDoublesAtom,
} from "./-Atoms";

import DockFunctions from "./GlobalFunctions/DockFunctions";

const crypto = require("crypto");

function UserMenu({
  user,
  firebase,
  so,
  a,
  click,
  close,
  notificationSettingsMenuAnchor,
  handleNotificationSettingsClose,
  handleNotificationSettingsClick,
  userTheme,
  handleThemeChange,
  theme,
  setQrDialogOpen,
}) {
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [isElectron] = useAtom(isElectronAtom);
//   const [isAdmin] = useAtom(isAdminAtom);
  const [showDoubles, setShowDoubles] = useAtom(showDoublesAtom);
  const history = useHistory();

  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setIsDarkMode(e.matches ? true : false)
      );
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);
  const [notificationTomorrowsJob, setNotificationTomorrowsJob] =
    useState(true);
  const [notificationNextDayChangesMe, setNotificationNextDayChangesMe] =
    useState(true);

  const savePushNotificationSubscriptionToUserData = firebase
    .functions()
    .httpsCallable("savePushNotificationSubscriptionToUserData");

  const [
    notificationsNotSupportedDialogOpen,
    setNotificationsNotSupportedDialogOpen,
  ] = useState(false);

  const setupNotifications = async () => {
    if (!isPushNotificationSupported()) {
      setNotificationsNotSupportedDialogOpen(true);
      return;
    }
    setIsRegisteringNotifications(true);
    const permission = await askUserPermission();
    if (permission !== "granted") return;
    registerServiceWorker();
    const serviceWorker = await navigator.serviceWorker.ready;
    const subscriptionRaw = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BKluKzkYf9wTIVmdWJMy9szdvr-utCUC5XqlAZgLvcTrqDAuGYynQMFZaHYFLozzyxukkGZ6-FxjGYvUxPv2x7w",
    });
    const subscriptionString = JSON.stringify(subscriptionRaw);
    const subscription = JSON.parse(subscriptionString);
    console.log(subscription);
    const success = await savePushNotificationSubscriptionToUserData({
      subscription: subscription,
    });
    setIsRegisteringNotifications(false);
    console.log(success);
  };

  const [notificationRegistered, setNotificationRegistered] = useState(false);

  useEffect(() => {
    const checkRegistered = async () => {
      if (!currentStaffData?.notificationSubscriptions) return;

      if (!isPushNotificationSupported()) return;
      if (!("serviceWorker" in navigator)) return;

      const serviceWorkers = await navigator.serviceWorker.getRegistrations();

      if (serviceWorkers.length === 0) return;

      const subscription = await getUserSubscription();

      const subscriptionId = createHash(JSON.stringify(subscription));
      currentStaffData.notificationSubscriptions.forEach((sub) => {
        if (sub.id === subscriptionId) {
          setNotificationRegistered(true);
        }
      });
    };
    checkRegistered();
  }, [currentStaffData, setNotificationRegistered]);

  const [isRegisteringNotifications, setIsRegisteringNotifications] =
    useState(false);

  const [themeSettingsMenuAnchor, setThemeSettingsMenuAnchor] = useState(null);

  const handleThemeSettingsClose = () => {
    setThemeSettingsMenuAnchor(null);
  };

  const forceReload = firebase.functions().httpsCallable("forceReload");
  const forceReloadNonAdmins = firebase
    .functions()
    .httpsCallable("forceReloadNonAdmins");
  const [forceReloadText, setForceReloadText] = useState("Force Reload");
  const [forceReloadNonAdminsText, setForceReloadNonAdminsText] = useState(
    "Force Reload (Non Admins)"
  );

  const { hideDock } = DockFunctions();
  const [isAlex, setIsAlex] = useState(false);
  useEffect(() => {
    if (currentStaffData.id === "8jXVYoCymxHLQngklbWE") {
      setIsAlex(true);
    } else {
      setIsAlex(false);
    }
  }, [currentStaffData, setIsAlex]);

  //   const sendLocalNotification = firebase
  //     .functions()
  //     .httpsCallable("sendLocalNotification");

  const iconStyle = {
    marginRight: "20px",
    color: theme === "dark" ? "#ffffff" : "#000000",
  };
  const pStyle = {
    paddingRight: "20px",
    color: theme === "dark" ? "#ffffff" : "#000000",
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", color: "red !important" }}
    >
      {isMobileBrowser ? (
        <div
          onClick={() => {
            setQrDialogOpen(true);
            hideDock();
          }}
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ProgressCircle
            loginTokenCreated={currentStaffData.loginTokenCreated}
            loginToken={currentStaffData.loginToken}
            theme={theme}
          />
        </div>
      ) : null}
      {!isElectron ? (
        <IconButton
          onClick={
            notificationRegistered
              ? handleNotificationSettingsClick
              : setupNotifications
          }
          // disabled={notificationRegistered}
        >
          {notificationRegistered ? (
            <NotificationsActiveIcon
              sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
            />
          ) : isRegisteringNotifications ? (
            <CircularProgress size={32} />
          ) : (
            <NotificationsIcon
              sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
            />
          )}
        </IconButton>
      ) : null}
      {isElectron ? (
        <Button variant="contained" size="small" onClick={so}>
          Logout
        </Button>
      ) : null}

      <IconButton onClick={click} style={{ marginRight: -16 }}>
        <Avatar alt={user.displayName} src={user.photoURL} />
      </IconButton>
      <Dialog
        onClose={() => {
          setNotificationsNotSupportedDialogOpen(false);
        }}
        open={notificationsNotSupportedDialogOpen}
      >
        <DialogTitle>Notifications Not Supported 🫠</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you are on an iPhone, ensure you are on iOS 16.4 or later & also
            have the app installed to your homescreen, it wont work in a
            browser.
            <br />
            <br />
            If you are on an Android phone, good luck you are on your own 🤷🏼‍♂️
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Menu
        id="theme-settings-menu"
        anchorEl={themeSettingsMenuAnchor}
        keepMounted
        open={Boolean(themeSettingsMenuAnchor)}
        onClose={handleThemeSettingsClose}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            width: 400,
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
      >
        <div>
          <ToggleButtonGroup
            color="primary"
            value={userTheme}
            exclusive
            onChange={handleThemeChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginRight: 2,
              marginLeft: 2,
            }}
          >
            <ToggleButton
              value="light"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <LightModeIcon style={{ marginRight: 8 }} fontSize="small" />
                <span style={{ fontSize: 10 }}>Light</span>
              </div>
            </ToggleButton>
            <ToggleButton
              value="automatic"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <SettingsBrightnessIcon
                  style={{ marginRight: 8 }}
                  fontSize="small"
                />
                <span style={{ fontSize: 10 }}>Auto</span>
              </div>
            </ToggleButton>
            <ToggleButton
              value="dark"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DarkModeIcon style={{ marginRight: 8 }} fontSize="small" />
                <span style={{ fontSize: 10 }}>Dark</span>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Menu>

      <Menu
        id="notification-settings-menu"
        anchorEl={notificationSettingsMenuAnchor}
        keepMounted
        open={Boolean(notificationSettingsMenuAnchor)}
        onClose={handleNotificationSettingsClose}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            width: 280,
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
      >
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            color: theme === "dark" ? "#ffffff" : "#000000",
          }}
        >
          Notification Settings
        </p>
        <MenuItem
          onClick={() => {
            const newVal = !notificationTomorrowsJob;
            setNotificationTomorrowsJob(newVal);
            try {
              firebase
                .firestore()
                .collection("StaffData")
                .doc(user?.staffData?.id)
                .update({ notificationTomorrowsJob: newVal });
            } catch (e) {
              console.log("weird call");
            }
          }}
        >
          <FormControlLabel
            onClick={(event) => {
              event.stopPropagation();
            }}
            control={
              <Switch
                checked={notificationTomorrowsJob}
                onChange={(e) => {
                  setNotificationTomorrowsJob(e.target.checked);
                  try {
                    firebase
                      .firestore()
                      .collection("StaffData")
                      .doc(user?.staffData?.id)
                      .update({ notificationTomorrowsJob: e.target.checked });
                  } catch (e) {
                    console.log("weird call v2");
                  }
                }}
              />
            }
            sx={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
            label="Tomorrow's Job"
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            const newVal = !notificationNextDayChangesMe;
            setNotificationNextDayChangesMe(newVal);
            firebase
              .firestore()
              .collection("StaffData")
              .doc(user?.staffData?.id)
              .update({ notificationNextDayChangesMe: newVal });
          }}
        >
          <FormControlLabel
            onClick={(event) => {
              event.stopPropagation();
            }}
            control={
              <Switch
                checked={notificationNextDayChangesMe}
                onChange={(e) => {
                  setNotificationNextDayChangesMe(e.target.checked);
                  firebase
                    .firestore()
                    .collection("StaffData")
                    .doc(user?.staffData?.id)
                    .set(
                      { notificationNextDayChangesMe: e.target.checked },
                      { merge: true }
                    );
                }}
              />
            }
            sx={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
            label="Next Day Changes (Me)"
          />
        </MenuItem>
      </Menu>

      <Menu
        id="user-menu"
        anchorEl={a}
        keepMounted
        open={Boolean(a)}
        onClose={close}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
      >
        <MenuItem disabled>
          <p style={pStyle}>{user.displayName}</p>
        </MenuItem>
        <MenuItem disabled>
          <p style={pStyle}>{user.email}</p>
        </MenuItem>
        {!isElectron ? <Divider /> : null}
        {!isElectron ? (
          <MenuItem
            onClick={async () => {
              history.push("/staff");
            }}
          >
            <AccountCircleIcon sx={iconStyle} />
            <p style={pStyle}>My Info</p>
          </MenuItem>
        ) : null}

        <Divider />

        <ToggleButtonGroup
          color="primary"
          value={userTheme}
          exclusive
          onChange={handleThemeChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 1,
          }}
        >
          <ToggleButton
            value="light"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <LightModeIcon style={{ marginRight: 8 }} fontSize="small" />
              <span style={{ fontSize: 10 }}>Light</span>
            </div>
          </ToggleButton>
          <ToggleButton
            value="automatic"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SettingsBrightnessIcon
                style={{ marginRight: 8 }}
                fontSize="small"
              />
              <span style={{ fontSize: 10 }}>Auto</span>
            </div>
          </ToggleButton>
          <ToggleButton
            value="dark"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <DarkModeIcon style={{ marginRight: 8 }} fontSize="small" />
              <span style={{ fontSize: 10 }}>Dark</span>
            </div>
          </ToggleButton>
        </ToggleButtonGroup>

        <Divider />

        {/* <ToggleButtonGroup
          color="primary"
          value={userTheme}
          exclusive
          onChange={handleThemeChange}
          sx={{
          }}
        > */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ToggleButton
            value={showDoubles}
            selected={showDoubles}
            sx={{
              height: 40,
              width: 243,
              marginBottom: 1,
              marginTop: 1,
            }}
            onChange={(e, newValue) => {
              setShowDoubles((prev) => !prev);
              firebase
                .firestore()
                .collection("StaffData")
                .doc(user.staffData.id)
                .update({ showDoubles: !newValue });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {showDoubles ? (
                <div>
                  <VisibilityIcon style={{ marginRight: 8 }} fontSize="small" />
                  <span style={{ fontSize: 10 }}>Hide Doubled Staff</span>
                </div>
              ) : (
                <div>
                  <VisibilityOffIcon
                    style={{ marginRight: 8 }}
                    fontSize="small"
                  />
                  <span style={{ fontSize: 10 }}>Show Doubled Staff</span>
                </div>
              )}
            </div>
          </ToggleButton>
        </div>

        {isAlex ? <Divider /> : null}

        {isAlex ? (
          <MenuItem
            onClick={async () => {
              setForceReloadText("Reloading Instances...");
              await forceReload();
              setForceReloadText("Force Reload");
              close();
            }}
            sx={{ marginTop: 1 }}
          >
            <CachedIcon sx={iconStyle} />
            <p style={pStyle}>{forceReloadText}</p>
          </MenuItem>
        ) : null}
        {isAlex ? <Divider /> : null}
        {isAlex ? (
          <MenuItem
            onClick={async () => {
              setForceReloadNonAdminsText("Reloading Instances...");
              await forceReloadNonAdmins();
              setForceReloadNonAdminsText("Force Reload (Non Admins)");
              close();
            }}
          >
            <CachedIcon sx={iconStyle} />
            <p style={pStyle}>{forceReloadNonAdminsText}</p>
          </MenuItem>
        ) : null}
        {isAlex ? <Divider /> : null}
        {isAlex ? (
          <MenuItem
            onClick={async () => {
              await firebase
                .firestore()
                .collection("-Force Reload")
                .doc("mrGrinchPlay")
                .update({ mrGrinchPlay: true });
              setTimeout(async () => {
                await firebase
                  .firestore()
                  .collection("-Force Reload")
                  .doc("mrGrinchPlay")
                  .update({ mrGrinchPlay: false });
                close();
              }, 200);
            }}
          >
            <ParkIcon sx={iconStyle} />
            <p style={pStyle}>You're A Mean One Mr Grinch</p>
          </MenuItem>
        ) : null}
        {/* {isAlex ? (
            <div>
              <Divider />
              <MenuItem
                onClick={async () => {
                  sendLocalNotification();
                }}
              >
                <NotificationsActiveIcon
                  sx={iconStyle}
                />
                <p
                  style={pStyle}
                >
                  Test Local Notification
                </p>
              </MenuItem>
            </div>
          ) : null} */}
        <Divider />
        <MenuItem onClick={so}>
          <ExitToAppIcon
            style={{
              paddingRight: "10px",
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          />
          <span
            style={{
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          >
            Logout
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
  return await Notification.requestPermission();
}

function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js");
}

function getUserSubscription() {
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

function createHash(input) {
  const md5sum = crypto.createHash("md5");
  md5sum.update(Buffer.from(input));
  // console.log(md5sum.digest("hex"));
  return md5sum.digest("hex");
}
