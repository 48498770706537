import React, { useState, useEffect, useRef, useMemo } from "react";
import debounce from "lodash.debounce";

import eipimage from "../../../GlobalComponents/eip.png";
import { CircularProgress } from "@mui/material";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Dialog } from "@mui/material";

import "./shootInfo.css";

import { useAtom } from "jotai";
import { themeAtom, isElectronAtom } from "../../../-Atoms";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const { DateTime } = require("luxon");

const SetupAccordion = ({
  setup,
  handleImageClick,
  defaultExpanded,
  setups,
  setSetups,
  clientData,
  firebase,
}) => {
  const [theme] = useAtom(themeAtom);
  const [isElectron] = useAtom(isElectronAtom);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState(setup.name || "");
  const [contextMenu, setContextMenu] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [selectedEip, setSelectedEip] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [localNotes, setLocalNotes] = useState(setup.notes || "");
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const processImage = async (file) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();

    let imageFile = file;
    if (file.name.toLowerCase().endsWith(".heic")) {
      const heic2any = (await import("heic2any")).default;
      const blob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.85,
      });
      imageFile = new File([blob], file.name.replace(/\.heic$/i, ".jpg"), {
        type: "image/jpeg",
      });
    }

    return new Promise((resolve, reject) => {
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        const maxDimension = 2600;

        if (width > height && width > maxDimension) {
          height = (height * maxDimension) / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width = (width * maxDimension) / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(new File([blob], imageFile.name, { type: "image/jpeg" }));
          },
          "image/jpeg",
          0.78
        );
      };

      img.onerror = reject;

      const reader = new FileReader();
      reader.onload = (e) => (img.src = e.target.result);
      reader.readAsDataURL(imageFile);
    });
  };

  const handleImageSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    setIsImageUploading(true);
    try {
      const MAX_CONCURRENT_UPLOADS = 3;
      const RETRY_DELAY = 1000;
      const MAX_RETRIES = 3;

      const uploadFile = async (file, retries = 0) => {
        try {
          const validTypes = [
            "image/jpeg",
            "image/jpg",
            "image/heic",
            "image/png",
          ];
          if (
            !validTypes.includes(file.type.toLowerCase()) &&
            !file.name.toLowerCase().endsWith(".heic")
          ) {
            throw new Error(`Invalid file type: ${file.type}`);
          }

          const processedFile = await processImage(file);

          const baseName = file.name.split(".")[0];
          const truncatedName = baseName.slice(0, 24);
          const randomString = Math.floor(Math.random() * 100000)
            .toString()
            .padStart(5, "0");
          const newFileName = `${truncatedName}_${randomString}.jpg`;

          // Upload to Dropbox
          const path = `/-Client Setups/${clientData.id}/${setup.id}/Images/${newFileName}`;
          const fileData = await processedFile.arrayBuffer();

          const res = await dbx.filesUpload({
            path,
            autorename: false,
            mode: "add",
            mute: false,
            contents: fileData,
          });

          // Get sharing link
          let url;
          try {
            const linkData = await dbx.sharingListSharedLinks({
              path: res.result.path_display,
              direct_only: true,
            });
            url = linkData.result.links[0].url;
          } catch {
            const shareData = await dbx.sharingCreateSharedLinkWithSettings({
              path: res.result.path_display,
              settings: {
                requested_visibility: "public",
                audience: "public",
                access: "viewer",
              },
            });
            url = shareData.result.url;
          }

          return { name: newFileName, url: url.replace("dl=0", "raw=1") };
        } catch (error) {
          if (error.status === 429 && retries < MAX_RETRIES) {
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return uploadFile(file, retries + 1);
          } else {
            throw error;
          }
        }
      };

      const uploadFiles = async (files) => {
        const results = [];
        for (let i = 0; i < files.length; i += MAX_CONCURRENT_UPLOADS) {
          const batch = files.slice(i, i + MAX_CONCURRENT_UPLOADS);
          const batchResults = await Promise.all(batch.map(uploadFile));
          results.push(...batchResults);
        }
        return results;
      };

      const newImages = await uploadFiles(files);

      updateSetups((setupObj) => {
        setupObj.images = [...setupObj.images, ...newImages];
      });
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setIsImageUploading(false);
      if (imageInputRef.current) imageInputRef.current.value = "";
    }
  };

  const debouncedUpdateNotes = useMemo(
    () =>
      debounce((notes) => {
        const newSetups = JSON.parse(JSON.stringify(setups));
        const studioKey = Object.keys(newSetups).find((key) =>
          newSetups[key].some((s) => s.id === setup.id)
        );
        if (studioKey) {
          const setupIndex = newSetups[studioKey].findIndex(
            (s) => s.id === setup.id
          );
          if (setupIndex !== -1) {
            newSetups[studioKey][setupIndex].notes = notes;
            setSetups(newSetups);
            firebase
              .firestore()
              .collection("ClientDatabase")
              .doc(clientData.id)
              .update({ setups: newSetups });
          }
        }
      }, 2000),
    [setups, setup.id, firebase, clientData.id]
  );

  useEffect(() => {
    setLocalNotes(setup.notes || "");
  }, [setup.notes]);

  const handleNotesChange = (e) => {
    const newValue = e.target.value;
    setLocalNotes(newValue);
    debouncedUpdateNotes(newValue);
  };

  const updateSetups = (updateFn) => {
    const newSetups = JSON.parse(JSON.stringify(setups));
    const studioKey = Object.keys(newSetups).find((key) =>
      newSetups[key].some((s) => s.id === setup.id)
    );
    if (studioKey) {
      const setupIndex = newSetups[studioKey].findIndex(
        (s) => s.id === setup.id
      );
      if (setupIndex !== -1) {
        updateFn(newSetups[studioKey][setupIndex], newSetups, studioKey);
        setSetups(newSetups);
        firebase
          .firestore()
          .collection("ClientDatabase")
          .doc(clientData.id)
          .update({ setups: newSetups });
      }
    }
  };

  const handleRename = () => {
    updateSetups((setupObj) => {
      setupObj.name = newName;
    });
    setRenameDialogOpen(false);
  };

  const handleDeleteSetup = () => {
    updateSetups((_, newSetups, studioKey) => {
      newSetups[studioKey] = newSetups[studioKey].filter(
        (s) => s.id !== setup.id
      );
    });
    setConfirmDeleteOpen(false);
    setContextMenu(null);
  };

  const handleDeleteEip = () => {
    updateSetups((setupObj) => {
      setupObj.eips = setupObj.eips.filter(
        (eip) => eip.name !== selectedEip.name
      );
    });
    setContextMenu(null);
    setSelectedEip(null);
  };

  const handleDeleteImage = () => {
    setContextMenu(null);
    setSelectedImage(null);
    updateSetups((setupObj) => {
      setupObj.images = setupObj.images.filter(
        (image) => image.name !== selectedImage.name
      );
    });
  };

  const handleDownloadImage = () => {
    const link = document.createElement("a");
    link.href = selectedImage.url;
    link.download = selectedImage.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setContextMenu(null);
    setSelectedImage(null);
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    setIsUploading(true);
    try {
      const newEips = await Promise.all(
        files.map(async (file) => {
          const baseName = file.name.slice(0, -4);
          const truncatedName = baseName.slice(0, 24);
          const randomString = Math.floor(Math.random() * 100000)
            .toString()
            .padStart(5, "0");
          const newFileName = `${truncatedName}_${randomString}.eip`;
          const path = `/-Client Setups/${clientData.id}/${setup.id}/EIPS/${newFileName}`;
          const fileData = await file.arrayBuffer();

          const res = await dbx.filesUpload({
            path,
            autorename: false,
            mode: "add",
            mute: false,
            contents: fileData,
          });

          let url;
          try {
            const linkData = await dbx.sharingListSharedLinks({
              path: res.result.path_display,
              direct_only: true,
            });
            url = linkData.result.links[0].url;
          } catch {
            const shareData = await dbx.sharingCreateSharedLinkWithSettings({
              path: res.result.path_display,
              settings: {
                requested_visibility: "public",
                audience: "public",
                access: "viewer",
              },
            });
            url = shareData.result.url;
          }

          return { name: newFileName, url: url.replace("dl=0", "raw=1") };
        })
      );

      updateSetups((setupObj) => {
        setupObj.eips = [...setupObj.eips, ...newEips];
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) fileInputRef.current.value = "";
    }
  };

  const commonStyles = {
    eipContainer: {
      display: "flex",
      alignItems: "center",
      background:
        theme === "dark"
          ? "rgba(255, 255, 255, 0.08)"
          : "rgba(30, 30, 30, 0.08)",
      padding: "4px 5px 2px 5px",
      borderRadius: 8,
      cursor: "pointer",
      marginBottom: 4,
    },
    accordion: {
      background:
        theme === "dark"
          ? "rgba(255, 255, 255, 0.05)"
          : "rgba(30, 30, 30, 0.05)",
    },
    button: { fontSize: "13px" },
  };

  return (
    <>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          setContextMenu({ mouseX: e.clientX + 2, mouseY: e.clientY - 6 });
          setSelectedEip(null);
          setSelectedImage(null);
        }}
        style={{
          marginBottom: 10,
          position: "relative",
          maxWidth: 740,
        }}
      >
        <Accordion
          defaultExpanded={defaultExpanded}
          sx={commonStyles.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: 20,
              }}
            >
              <div>
                {DateTime.fromISO(setup.date).toFormat("dd/MM/yyyy")}
                {setup.name ? ` - ${setup.name}` : ""}
              </div>
              {isElectron && (
                <Button
                  sx={commonStyles.button}
                  onClick={(e) => e.stopPropagation()}
                >
                  Copy EIPs
                </Button>
              )}
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div style={{ marginBottom: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <div>EIPS:</div>
                <div>{setup.eips.length}</div>
              </div>

              <div
                style={{ paddingLeft: 10, width: "100%", whiteSpace: "nowrap" }}
              >
                {setup.eips.map((eip) => (
                  <div
                    key={eip.name}
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = eip.url;
                      link.download = eip.name;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedEip(eip);
                      setContextMenu({
                        mouseX: e.clientX + 2,
                        mouseY: e.clientY - 6,
                      });
                    }}
                    style={commonStyles.eipContainer}
                  >
                    <img
                      src={eipimage}
                      alt="eip"
                      style={{
                        height: 20,
                        padding: "0 8px 0 2px",
                        marginTop: -3,
                      }}
                    />
                    <div
                      style={{
                        marginBottom: 4,
                        textOverflow: "ellipsis",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {eip.name}
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ fontWeight: 500 }}>Images:</div>
                  <div>{setup.images.length}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    WebkitOverflowScrolling: "touch",
                    height: 400,
                  }}
                >
                  {setup.images.map((image, index) => (
                    <img
                      key={image.name}
                      src={image.url}
                      alt={image.name}
                      onClick={() => handleImageClick(setup.images, index)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedImage(image);
                        setContextMenu({
                          mouseX: e.clientX + 2,
                          mouseY: e.clientY - 6,
                        });
                      }}
                      style={{
                        maxHeight: 400,
                        height: "auto",
                        maxWidth: 300,
                        width: "auto",
                        objectFit: "cover",
                        cursor: "pointer",
                        flexShrink: 0,
                      }}
                      loading="lazy"
                    />
                  ))}
                </div>
              </div>

              <TextField
                fullWidth
                multiline
                size="small"
                label="Notes"
                value={localNotes}
                variant="filled"
                sx={{ marginTop: 2 }}
                InputProps={{ disableUnderline: true }}
                onChange={handleNotesChange}
              />
            </div>
          </AccordionDetails>

          <AccordionActions>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                sx={commonStyles.button}
                onClick={() => setRenameDialogOpen(true)}
              >
                Rename
              </Button>
              <div style={{ display: "flex", gap: "8px" }}>
                <input
                  type="file"
                  accept=".eip"
                  multiple
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
                <input
                  type="file"
                  accept=".jpg,.jpeg,.heic"
                  multiple
                  ref={imageInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <Button
                  sx={commonStyles.button}
                  onClick={() => fileInputRef.current?.click()}
                  disabled={isUploading}
                >
                  {isUploading && (
                    <CircularProgress size={16} sx={{ marginRight: 1 }} />
                  )}
                  Add EIP
                </Button>
                <Button
                  sx={commonStyles.button}
                  onClick={() => imageInputRef.current?.click()}
                  disabled={isImageUploading}
                >
                  {isImageUploading && (
                    <CircularProgress size={16} sx={{ marginRight: 1 }} />
                  )}
                  Add Photo
                </Button>
              </div>
            </div>
          </AccordionActions>
        </Accordion>
      </div>

      <Menu
        open={contextMenu !== null}
        onClose={() => {
          setContextMenu(null);
          setSelectedEip(null);
          setSelectedImage(null);
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {selectedEip ? (
          <MenuItem onClick={handleDeleteEip}>Delete EIP</MenuItem>
        ) : selectedImage ? (
          <>
            <MenuItem onClick={handleDeleteImage}>Delete Image</MenuItem>
            <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => setConfirmDeleteOpen(true)}>
            Delete Setup
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <div style={{ padding: 20, minWidth: 300 }}>
          <div>Are you sure you want to delete this setup?</div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 20,
            }}
          >
            <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
            <Button onClick={handleDeleteSetup} color="error">
              Delete
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={renameDialogOpen}
        onClose={() => setRenameDialogOpen(false)}
      >
        <div style={{ padding: 20, minWidth: 300 }}>
          <TextField
            autoFocus
            fullWidth
            label="Setup Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleRename();
              } else if (e.key === "Escape") setRenameDialogOpen(false);
            }}
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 20,
            }}
          >
            <Button onClick={() => setRenameDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleRename} variant="contained">
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default SetupAccordion;
