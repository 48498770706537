import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Dialog } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

import "./shootInfo.css";

import OperatorChecklistModal from "./OperatorChecklistModal";

import NotesContainer from "../../../GlobalComponents/NotesContainer";

import { useAtom } from "jotai";
import { themeAtom, isAdminAtom, isElectronAtom } from "../../../-Atoms";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import CameraSettings from "../CameraSettings";

import ShootInfoMenu from "./ShootInfoMenu";

import { v4 as uuidv4 } from "uuid";

import SetupAccordion from "./SetupAccordion";

const ShootInfo = ({
  jobData,
  staffData,
  findStaff,
  clientData,
  firebase,
  isDarkMode,
  modalWidth,
  isMobile,
  openOperatorChecklistModal,
  setOpenOperatorChecklistModal,
}) => {
  const [theme] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [isElectron] = useAtom(isElectronAtom);

  const handleOperatorChecklistModalClose = () => {
    setOpenOperatorChecklistModal(false);
  };

  // eslint-disable-next-line
  const [imagesProcessedNumbers, setImagesProcessedNumbers] = useState(
    jobData.imagesProcessedNumbers
  );

  useEffect(() => {
    setImagesProcessedNumbers(jobData.imagesProcessedNumbers);
  }, [jobData]);

  const [bookedStart, setBookedStart] = useState(
    jobData.bookedStart ? dayjs(jobData.bookedStart) : null
  );
  const [dueDate, setDueDate] = useState(
    jobData.dueDate ? dayjs(jobData.dueDate) : null
  );
  const [expressDueDate, setExpressDueDate] = useState(
    jobData.expressDueDate ? dayjs(jobData.expressDueDate) : null
  );

  const [photographer, setPhotographer] = useState("");
  const [additionalPhotographers, setAdditionalPhotographers] = useState([]);
  const [stylist, setStylist] = useState("");
  const [additionalStylists, setAdditionalStylists] = useState([]);
  const [operator, setOperator] = useState("");
  const [additionalOperators, setAdditionalOperators] = useState([]);
  const [imagesScheduledToBeShot, setImagesScheduledToBeShot] = useState(
    jobData.imagesScheduledToBeShot
  );
  const [jobTypeFull, setJobTypeFull] = useState(jobData.jobTypeFull);

  const [, setFileNaming] = useState(clientData.fileNaming);
  const [, setShootDrivePhoto] = useState(clientData.shootDrivePhoto);
  const [, setShootDriveVideo] = useState(clientData.shootDriveVideo);

  const [spreadsheetURL, setSpreadsheetURL] = useState(
    clientData.spreadsheetURL
  );

  const [setups, setSetups] = useState({});

  const [supplyLink, setSupplyLink] = useState("");
  useEffect(() => {
    function main() {
      if (!clientData.suppliedLinks) return;
      clientData.suppliedLinks.forEach((s) => {
        if (s.id === jobData.id) {
          setSupplyLink(s);
        }
      });
    }
    main();
  }, [clientData, jobData]);

  useEffect(() => {
    setBookedStart(jobData.bookedStart ? dayjs(jobData.bookedStart) : null);
    setDueDate(jobData.dueDate ? dayjs(jobData.dueDate) : null);
    setExpressDueDate(
      jobData.expressDueDate ? dayjs(jobData.expressDueDate) : null
    );

    setPhotographer(jobData.photographer);
    setAdditionalPhotographers(jobData.additionalPhotographers);
    setStylist(jobData.stylist);
    setAdditionalStylists(jobData.additionalStylists);
    setOperator(jobData.operator);
    setAdditionalOperators(jobData.additionalOperators);
    setImagesScheduledToBeShot(jobData.imagesScheduledToBeShot);
    setJobTypeFull(jobData.jobTypeFull);
  }, [jobData]);
  useEffect(() => {
    setFileNaming(clientData.fileNaming);
    setShootDrivePhoto(clientData.shootDrivePhoto);
    setShootDriveVideo(clientData.shootDriveVideo);

    setSpreadsheetURL(clientData.spreadsheetURL);
    setSetups(clientData.setups || {});
  }, [clientData]);

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const [sortedNotes, setSortedNotes] = useState([]);

  useEffect(() => {
    let tmp = [];

    jobData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    clientData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    setSortedNotes(sortNotes(tmp));
  }, [jobData, clientData]);

  const [selectedNotesFilter, setSelectedNotesFilter] = useState([]);

  const handleFilterChange = (event, newFilter) => {
    setSelectedNotesFilter(newFilter);
  };

  const filterButtonStyles = {
    fontSize: 11,
    fontWeight: "bold",
    padding: 0.8,
    border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
    borderRadius: "4px !important",
  };

  const accordionStyle = {
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(30, 30, 30, 0.05)",
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentSetup, setCurrentSetup] = useState(null);

  const handleImageClick = (setupImages, index) => {
    setCurrentSetup(setupImages);
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const handleNext = () => {
    setCurrentImageIndex((prev) =>
      prev === currentSetup.length - 1 ? 0 : prev + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? currentSetup.length - 1 : prev - 1
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <ShootInfoMenu
        jobData={jobData}
        clientData={clientData}
        spreadsheetURL={spreadsheetURL}
        isElectron={isElectron}
        isDev={isDev}
        isLife={jobData?.jobTypeFull?.includes("-L")}
        setOpenOperatorChecklistModal={setOpenOperatorChecklistModal}
      />
      <div
        style={{
          width: "100%",
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: -20,
        }}
      >
        <CameraSettings
          firebase={firebase}
          jobData={jobData}
          clientData={clientData}
        />
      </div>

      {supplyLink ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: 200, height: 34 }}
            style={{ margin: 8 }}
            size="small"
            onClick={(e) => {
              handleMenuClick(e);
            }}
          >
            Supply Link
          </Button>
        </div>
      ) : null}
      <Menu
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={handleMenuClose}
        disableScrollLock={true}
        style={{ marginTop: 10 }}
      >
        <MenuItem
          sx={{ width: 200 }}
          onClick={() => {
            navigator.clipboard.writeText(supplyLink.url);
            handleMenuClose();
          }}
        >
          Copy To Clipboard
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            window.open(supplyLink.url, "_blank");
            handleMenuClose();
          }}
        >
          Open Link
        </MenuItem>
      </Menu>
      <div style={{ marginBottom: 30 }}> </div>
      <ToggleButtonGroup
        value={selectedNotesFilter}
        onChange={handleFilterChange}
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 0.8,
        }}
        // orientation="vertical"
      >
        {isAdmin ? (
          <ToggleButton
            value="Pre-Production"
            size="small"
            sx={filterButtonStyles}
          >
            Pre-Production
          </ToggleButton>
        ) : null}
        <ToggleButton value="Photographer" size="small" sx={filterButtonStyles}>
          Photographer
        </ToggleButton>
        <ToggleButton value="Videographer" size="small" sx={filterButtonStyles}>
          Videographer
        </ToggleButton>
        <ToggleButton value="Operator" size="small" sx={filterButtonStyles}>
          Operator
        </ToggleButton>
        <ToggleButton value="Stylist" size="small" sx={filterButtonStyles}>
          Stylist
        </ToggleButton>
        <ToggleButton value="Retoucher" size="small" sx={filterButtonStyles}>
          Retoucher
        </ToggleButton>
      </ToggleButtonGroup>
      <NotesContainer
        key={"NotesContainer1"}
        sortedNotes={sortedNotes}
        selectedNotesFilter={selectedNotesFilter}
        isMobile={isMobile}
        modalWidth={modalWidth}
        theme={theme}
        newNoteModalOpen={false}
        setNewNoteModalOpen={() => {}}
        setNewSelectedNotesFilter={() => {}}
        setNewNoteKind={() => {}}
        setNewNotesText={() => {}}
        setNewNotesID={() => {}}
        setNewNote={() => {}}
        jobTab={false}
        clientTab={false}
        shootTab={true}
        kind={"Job"}
        jobData={jobData}
      />
      <NotesContainer
        key={"NotesContainer2"}
        sortedNotes={sortedNotes}
        selectedNotesFilter={selectedNotesFilter}
        isMobile={isMobile}
        modalWidth={modalWidth}
        theme={theme}
        newNoteModalOpen={false}
        setNewNoteModalOpen={() => {}}
        setNewSelectedNotesFilter={() => {}}
        setNewNoteKind={() => {}}
        setNewNotesText={() => {}}
        setNewNotesID={() => {}}
        setNewNote={() => {}}
        jobTab={false}
        clientTab={false}
        shootTab={true}
        kind={"Permanent"}
        jobData={jobData}
      />
      <div
        style={{
          paddingRight: 20,
          paddingLeft: 20,
          marginTop: 10,
          width: "100%",
          maxWidth: 780,
        }}
      >
        <Accordion
          defaultExpanded={jobData?.studio === "Studio 1"}
          sx={accordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            Studio 401
          </AccordionSummary>
          <AccordionDetails>
            {setups?.studio401 &&
              setups?.studio401?.map((setup, index) => (
                <SetupAccordion
                  setup={setup}
                  handleImageClick={handleImageClick}
                  defaultExpanded={index === 0}
                  setups={setups}
                  setSetups={setSetups}
                  clientData={clientData}
                  firebase={firebase}
                />
              ))}
          </AccordionDetails>
          <AccordionActions>
            <Button
              onClick={() => {
                const newSetups = JSON.parse(JSON.stringify(setups));
                if (!newSetups.studio401) newSetups.studio401 = [];
                newSetups.studio401.unshift({
                  name: "",
                  date: new Date().toISOString(),
                  id: uuidv4(),
                  eips: [],
                  images: [],
                  notes: "",
                });
                setSetups(newSetups);
                firebase
                  .firestore()
                  .collection("ClientDatabase")
                  .doc(clientData.id)
                  .update({
                    setups: newSetups,
                  });
              }}
            >
              New Setup
            </Button>
          </AccordionActions>
        </Accordion>

        {/* //////////////////////////// */}

        <Accordion
          defaultExpanded={jobData?.studio === "Studio 4"}
          sx={accordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            Studio 301
          </AccordionSummary>
          <AccordionDetails>
            {setups?.studio301 &&
              setups?.studio301?.map((setup, index) => (
                <SetupAccordion
                  setup={setup}
                  handleImageClick={handleImageClick}
                  defaultExpanded={index === 0}
                  setups={setups}
                  setSetups={setSetups}
                  clientData={clientData}
                  firebase={firebase}
                />
              ))}
          </AccordionDetails>

          <AccordionActions>
            <Button
              onClick={() => {
                const newSetups = JSON.parse(JSON.stringify(setups));
                if (!newSetups.studio301) newSetups.studio301 = [];
                newSetups.studio301.unshift({
                  name: "",
                  date: new Date().toISOString(),
                  id: uuidv4(),
                  eips: [],
                  images: [],
                  notes: "",
                });
                setSetups(newSetups);
                firebase
                  .firestore()
                  .collection("ClientDatabase")
                  .doc(clientData.id)
                  .update({
                    setups: newSetups,
                  });
              }}
            >
              New Setup
            </Button>
          </AccordionActions>
        </Accordion>

        {/* //////////////////////////// */}

        <Accordion
          defaultExpanded={jobData?.studio === "Studio 3"}
          sx={accordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            Studio 302
          </AccordionSummary>
          <AccordionDetails>
            {setups?.studio302 &&
              setups?.studio302?.map((setup, index) => (
                <SetupAccordion
                  setup={setup}
                  handleImageClick={handleImageClick}
                  defaultExpanded={index === 0}
                  setups={setups}
                  setSetups={setSetups}
                  clientData={clientData}
                  firebase={firebase}
                />
              ))}
          </AccordionDetails>

          <AccordionActions>
            <Button
              onClick={() => {
                const newSetups = JSON.parse(JSON.stringify(setups));
                if (!newSetups.studio302) newSetups.studio302 = [];
                newSetups.studio302.unshift({
                  name: "",
                  date: new Date().toISOString(),
                  id: uuidv4(),
                  eips: [],
                  images: [],
                  notes: "",
                });
                setSetups(newSetups);
                firebase
                  .firestore()
                  .collection("ClientDatabase")
                  .doc(clientData.id)
                  .update({
                    setups: newSetups,
                  });
              }}
            >
              New Setup
            </Button>
          </AccordionActions>
        </Accordion>

        {/* //////////////////////////// */}

        <Accordion
          defaultExpanded={
            jobData?.studio === "Studio 2A" ||
            jobData?.studio === "Studio 2B" ||
            jobData?.studio === "Studio 2C"
          }
          sx={accordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            Studio 403 (GHOST ROOM)
          </AccordionSummary>
          <AccordionDetails>
            {setups?.studio403 &&
              setups?.studio403?.map((setup, index) => (
                <SetupAccordion
                  setup={setup}
                  handleImageClick={handleImageClick}
                  defaultExpanded={index === 0}
                  setups={setups}
                  setSetups={setSetups}
                  clientData={clientData}
                  firebase={firebase}
                />
              ))}
          </AccordionDetails>
          <AccordionActions>
            <Button
              onClick={() => {
                const newSetups = JSON.parse(JSON.stringify(setups));
                if (!newSetups.studio403) newSetups.studio403 = [];
                newSetups.studio403.unshift({
                  name: "",
                  date: new Date().toISOString(),
                  id: uuidv4(),
                  eips: [],
                  images: [],
                  notes: "",
                });
                setSetups(newSetups);
                firebase
                  .firestore()
                  .collection("ClientDatabase")
                  .doc(clientData.id)
                  .update({
                    setups: newSetups,
                  });
              }}
            >
              New Setup
            </Button>
          </AccordionActions>
        </Accordion>
      </div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          padding: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "100%",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <MobileDatePicker
                label={"Shoot Date"}
                format="ddd DD/MM/YYYY"
                value={bookedStart || null}
                disabled
                onChange={(d) => {}}
                slotProps={{
                  textField: {
                    variant: "filled",
                    size: "small",
                    InputProps: { disableUnderline: true },
                    fullWidth: true,
                    error: dueDate ? false : true,
                  },
                }}
              />
              <MobileDatePicker
                label={"Due Date"}
                format="ddd DD/MM/YYYY"
                value={dueDate || null}
                onChange={(d) => {
                  setDueDate(d);
                  updateJobData(
                    firebase,
                    jobData.id,
                    "dueDate",
                    d ? d.toISOString() : null
                  );
                }}
                slotProps={{
                  textField: {
                    variant: "filled",
                    size: "small",
                    InputProps: { disableUnderline: true },
                    fullWidth: true,
                    error: dueDate ? false : true,
                  },
                }}
              />
            </div>

            <MobileDatePicker
              label={"Express Due Date"}
              format="ddd DD/MM/YYYY"
              value={expressDueDate || null}
              onChange={(d) => {
                setExpressDueDate(d);
                updateJobData(
                  firebase,
                  jobData.id,
                  "expressDueDate",
                  d ? d.toISOString() : null
                );
              }}
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  InputProps: { disableUnderline: true },
                  fullWidth: true,
                  error: dueDate ? false : true,
                },
              }}
            />
          </LocalizationProvider>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 10,
              marginTop: 10,
            }}
          >
            <TextField
              label={
                jobData?.jobTypeFull?.includes("-V") ||
                jobData?.jobTypeFull?.includes("-E")
                  ? "Videographer"
                  : "Photographer"
              }
              value={findStaff(photographer, staffData) || ""}
              size="small"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              fullWidth
            />

            {additionalPhotographers &&
              additionalPhotographers.map((ao) => (
                <TextField
                  key={ao}
                  label={
                    jobData?.jobTypeFull?.includes("-V") ||
                    jobData?.jobTypeFull?.includes("-E")
                      ? "Additional Videographer"
                      : "Additional Photographer"
                  }
                  value={findStaff(ao, staffData) || ""}
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                />
              ))}

            <TextField
              label={"Operator"}
              value={findStaff(operator, staffData) || ""}
              size="small"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              fullWidth
            />

            {additionalOperators &&
              additionalOperators.map((ao) => (
                <TextField
                  key={ao}
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                  label="Additional Operator"
                  value={findStaff(ao, staffData) || ""}
                  size="small"
                />
              ))}
            <TextField
              label={"Stylist"}
              value={findStaff(stylist, staffData) || ""}
              size="small"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              fullWidth
            />

            {additionalStylists &&
              additionalStylists.map((ao) => (
                <TextField
                  key={ao}
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                  label="Additional Stylist"
                  value={findStaff(ao, staffData) || ""}
                  size="small"
                />
              ))}
          </div>

          <TextField
            sx={{ marginTop: 2 }}
            multiline={true}
            disabled={true}
            size="small"
            label={
              jobTypeFull?.includes("(-V)")
                ? "Videos Scheduled"
                : "Images Scheduled"
            }
            value={imagesScheduledToBeShot || ""}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
          />
        </div>
      </div>

      {lightboxOpen && currentSetup && (
        <ImageLightbox
          images={currentSetup}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}
          onClose={() => setLightboxOpen(false)}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}

      <OperatorChecklistModal
        open={openOperatorChecklistModal}
        close={handleOperatorChecklistModalClose}
        jobData={jobData}
        firebase={firebase}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default ShootInfo;

const ImageLightbox = ({ images, currentIndex, onClose, onNext, onPrev }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  const VISIBLE_THUMBNAILS = 5;

  const getVisibleThumbnails = () => {
    const totalImages = images.length;
    let start = currentIndex - Math.floor(VISIBLE_THUMBNAILS / 2);
    let end = start + VISIBLE_THUMBNAILS;

    // Adjust start and end for edge cases
    if (start < 0) {
      start = 0;
      end = Math.min(VISIBLE_THUMBNAILS, totalImages);
    } else if (end > totalImages) {
      end = totalImages;
      start = Math.max(0, end - VISIBLE_THUMBNAILS);
    }

    return {
      thumbnails: images.slice(start, end),
      startIndex: start,
    };
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "ArrowLeft") {
        onPrev();
      } else if (e.key === "ArrowRight") {
        onNext();
      } else if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [onNext, onPrev, onClose]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      onNext();
    } else if (isRightSwipe) {
      onPrev();
    }
  };

  const { thumbnails, startIndex } = getVisibleThumbnails();

  return (
    <Dialog open={true} onClose={onClose} fullScreen>
      <div
        style={{
          position: "relative",
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div
          style={{
            position: "absolute",
            left: 20,
            top: 20,
            color: "white",
            zIndex: 1,
            backgroundColor: "rgba(255,255,255,0.1)",
            padding: "8px 12px",
            borderRadius: "4px",
          }}
        >
          {currentIndex + 1}/{images.length}
        </div>
        <img
          src={images[currentIndex].url}
          alt={images[currentIndex].name}
          style={{
            maxHeight: "98vh",
            maxWidth: "97.8vw",
            objectFit: "contain",
            userSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            pointerEvents: "none",
          }}
        />
        <div
          onClick={onPrev}
          style={{
            position: "absolute",
            left: 20,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            backgroundColor: "rgba(255,255,255,0.2)",
            borderRadius: "50%",
            padding: 10,
            zIndex: 1,
          }}
        >
          <ChevronLeftIcon style={{ fontSize: 30, color: "white" }} />
        </div>
        <div
          onClick={onNext}
          style={{
            position: "absolute",
            right: 20,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            backgroundColor: "rgba(255,255,255,0.2)",
            borderRadius: "50%",
            padding: 10,
            zIndex: 1,
          }}
        >
          <ChevronRightIcon style={{ fontSize: 30, color: "white" }} />
        </div>
        <div
          onClick={onClose}
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer",
            backgroundColor: "rgba(255,255,255,0.2)",
            borderRadius: "50%",
            padding: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon style={{ color: "white" }} />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            padding: "10px 20px",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          {thumbnails.map((image, i) => {
            const actualIndex = startIndex + i;
            return (
              <div
                key={actualIndex}
                onClick={() => {
                  const selectedIndex = images.findIndex(
                    (img) => img.url === image.url
                  );
                  if (selectedIndex !== -1) {
                    onNext(selectedIndex);
                  }
                }}
                style={{
                  cursor: "pointer",
                  border:
                    currentIndex === actualIndex
                      ? "2px solid white"
                      : "2px solid transparent",
                  borderRadius: "4px",
                  padding: "2px",
                  transition: "all 0.2s ease",
                }}
              >
                <img
                  src={image.url}
                  alt={image.name}
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "cover",
                    borderRadius: "2px",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};

function sortNotes(notes) {
  let newNotes = [...notes];
  newNotes.sort((a, b) => {
    var keyA = new Date(a.timestamp),
      keyB = new Date(b.timestamp);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  let tmp = [];

  newNotes.forEach((note, index) => {
    if (note.tags.includes("Pre-Production")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Photographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Videographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Operator")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Stylist")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Retoucher")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });

  return tmp;
}
const updateJobData = debounceCustom((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(id)
    .update({ [field]: value }, { merge: true })
    .catch((e) => console.log(e));
}, 400);

function debounceCustom(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function isDev() {
  return process.env.NODE_ENV === "development";
}
